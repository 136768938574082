import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  openLock?: boolean
}

const IconLock = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox={props.openLock ? '0 0 10 15' : '0 0 16 19'}
  >
    {props.openLock ? (
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M9.416 7.818h-.052c.3 0 .545.244.545.546V14a.545.545 0 0 1-.545.546H.636A.545.545 0 0 1 .091 14V8.364c0-.302.244-.546.545-.546h7.35l.002-.306V5.836h-.001c-.053-2.1-1.158-3.419-2.96-3.419-1.319 0-2.263.705-2.697 1.904H.818s.103-.373.153-.503C1.632 2.11 3.2.91 5.026.91c2.38 0 4.318 2.037 4.388 4.578h.002v2.331Zm-4.044 3.375a.91.91 0 1 0-.744 0l-.452 1.293a.182.182 0 0 0 .171.241h1.306a.182.182 0 0 0 .171-.242l-.452-1.292Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.625 8.636V7.32h-.003C14.516 3.51 11.61.454 8.04.454S1.563 3.51 1.458 7.321h-.003V8.636h-.127a.69.69 0 0 0-.691.69v8.71c0 .381.31.69.69.69h13.346a.69.69 0 0 0 .691-.69v-8.71a.69.69 0 0 0-.69-.69h-.05Zm-1.597 0v-.792h-.002c-.08-3.15-2.282-5.673-4.986-5.673-2.705 0-4.907 2.524-4.987 5.673h-.002v.792h9.977Zm-4.47 5.063a1.364 1.364 0 1 0-1.116 0l-.678 1.938A.273.273 0 0 0 7.02 16H8.98c.187 0 .32-.186.257-.363l-.678-1.938Z"
        fill={props.color}
      />
    )}
  </svg>
)

export default IconLock
