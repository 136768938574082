import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconBurger = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 20"
    fill="none"
  >
    <path
      fill={props.color}
      d="M0 2.046a1.5 1.5 0 0 1 1.5-1.5h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1-1.5-1.5ZM0 10.046a1.5 1.5 0 0 1 1.5-1.5h17a1.5 1.5 0 0 1 0 3h-17a1.5 1.5 0 0 1-1.5-1.5ZM0 18.046a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 0 3h-13a1.5 1.5 0 0 1-1.5-1.5Z"
    />
  </svg>
)

export default IconBurger
