import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconBuddy = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 18 19"
  >
    <path
      fill={props.color}
      d="M11.182 10.906c1.785 0 3.272-1.593 3.272-3.634 0-1.984-1.495-3.545-3.272-3.545-1.769 0-3.273 1.578-3.265 3.553 0 2.033 1.48 3.626 3.265 3.626ZM6.205 18.5h9.954c1.375 0 1.841-.447 1.841-1.22 0-2.08-2.653-4.943-6.818-4.943-4.173 0-6.818 2.862-6.818 4.944 0 .772.466 1.219 1.84 1.219Z"
    />
    <path
      fill={props.color}
      d="M7.205 9.206a5.056 5.056 0 0 1-.378-1.924c-.01-2.506 1.912-4.646 4.355-4.646.095 0 .189.004.282.01C10.755 1.484 9.537.727 8.182.727c-2.123 0-3.927 1.893-3.917 4.264 0 2.056 1.261 3.738 2.94 4.215ZM8.576 11.066c-.13-.004-.262-.007-.394-.007C3.174 11.06 0 14.493 0 16.991c0 .927.56 1.464 2.21 1.464h1.36a2.305 2.305 0 0 1-.297-1.174c0-1.47.898-2.987 2.24-4.094.957-.789 2.188-1.417 3.635-1.728a4.272 4.272 0 0 1-.572-.393Z"
    />
    <path
      fill={props.color}
      d="M13.62 12.69c1.75 1.218 2.744 2.906 2.744 4.301 0 .927-.56 1.464-2.21 1.464H5.53c-.857-.132-1.166-.545-1.166-1.174 0-2.082 2.645-4.944 6.818-4.944.885 0 1.701.13 2.439.353ZM11.963 3.83a3.018 3.018 0 0 0-.781-.103c-1.769 0-3.273 1.578-3.265 3.553 0 .766.21 1.47.568 2.05 2.005-.171 3.624-2.017 3.624-4.349 0-.4-.051-.786-.146-1.15Z"
    />
  </svg>
)

export default IconBuddy
