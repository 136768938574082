import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconExternPeople = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.34 5.585c0 3.246-2.433 5.739-5.233 5.739-2.813 0-5.233-2.493-5.233-5.713 0-3.181 2.433-5.609 5.233-5.609s5.234 2.376 5.234 5.583zM9.87 23.347H4.878c-2.054 0-2.787-.584-2.787-1.727 0-3.35 4.226-7.972 11.003-7.972.791 0 1.547.063 2.266.179a5.81 5.81 0 00-.412.459l.008-.038-3.86 3.827h-.002c-1.02 1.008-1.56 2.26-1.56 3.545 0 .588.113 1.17.335 1.727zm10.265 0h1.177c2.067 0 2.8-.584 2.8-1.727 0-.59-.131-1.218-.384-1.855l-2.544 2.512.004-.014c-.12.144-.248.284-.385.42l-.668.664zM16.239 14l-.327.324c-1.142 1.132-1.557 2.567-1.238 3.92.019.078.04.154.062.23.205.683.599 1.338 1.183 1.917.421.425 1.002.741 1.837.893l1.111-1.116a3.867 3.867 0 01-.877-.17 2.59 2.59 0 01-1.04-.622c-.714-.707-1.013-1.586-.897-2.433a2.71 2.71 0 01.13-.533c.147-.418.4-.817.76-1.172l.797-.793A12.764 12.764 0 0016.24 14zm6.39 3.889c.298.381.554.772.765 1.164l-.903.892c.078-.337.08-.706.02-1.062a2.954 2.954 0 00-.202-.68l.32-.314zm-.776-.872c.101.1.2.203.294.305l-.11.109a4.964 4.964 0 00-.184-.414zm-2.771 6.331h-1.95l2.113-2.093a3.13 3.13 0 00.764-1.182c.062-.177.105-.358.13-.54.112-.845-.186-1.719-.901-2.423a2.581 2.581 0 00-1.043-.62 4.027 4.027 0 00-.875-.172l1.111-1.116c.843.159 1.416.475 1.838.893.583.578.977 1.233 1.182 1.913.022.075.042.15.06.226.323 1.356-.091 2.794-1.235 3.928l-1.194 1.186zm-6.69 0H10.68c-.759-1.576-.44-3.386.94-4.749l2.077-2.058c-.074.34-.078.709-.019 1.063.04.237.107.468.2.679l-1.227 1.223c-1.124 1.114-1.219 2.65-.26 3.842zm3.657 0h-2.608a2.745 2.745 0 01-.255-.225l-.002-.002c-.49-.486-.705-1.035-.705-1.551 0-.517.215-1.062.698-1.541l.974-.971a5.388 5.388 0 001.24 1.854c.512.515 1.187.88 2.07 1.067l-1.153 1.142c-.084.084-.17.16-.259.227zm2.88-3.916l.466.04c.015-.097.022-.192.022-.287 0-.52-.215-1.07-.704-1.551l-.002-.002c-.293-.29-.733-.512-1.46-.579l-.456-.042a1.898 1.898 0 00-.022.29c0 .52.217 1.071.703 1.553.296.293.737.518 1.454.578z"
      fill={props.color}
    />
    <path
      d="M17.756 21.282l1.111-1.115c-.857-.072-1.474-.353-1.917-.792-1.227-1.216-1.227-2.936-.007-4.138l2.41-2.396c1.228-1.21 2.956-1.217 4.183 0 1.235 1.223 1.22 2.936.008 4.145l-1.235 1.216c.232.533.312 1.18.182 1.742l2.076-2.051c1.787-1.764 1.794-4.282-.007-6.067-1.808-1.792-4.335-1.778-6.121-.007l-2.527 2.504c-1.787 1.77-1.794 4.282.007 6.067.421.424 1.002.741 1.837.892zm.675-6.08l-1.11 1.115c.856.079 1.473.352 1.916.791 1.235 1.216 1.228 2.936.008 4.145l-2.411 2.39c-1.227 1.216-2.956 1.216-4.176 0-1.234-1.224-1.227-2.93-.007-4.138l1.227-1.224a2.918 2.918 0 01-.181-1.741l-2.077 2.058c-1.786 1.763-1.794 4.275.007 6.06 1.808 1.791 4.335 1.777 6.122.014l2.527-2.512c1.786-1.77 1.794-4.282-.007-6.067-.422-.417-.995-.734-1.838-.892z"
      fill={props.color}
    />
  </svg>
)

export default IconExternPeople
