import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconActivity = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 8"
  >
    <path
      d="M1.565 1.56h.864A.696.696 0 0 0 3.12.868.692.692 0 0 0 2.43.182h-.864A.69.69 0 0 0 .88.868c0 .379.313.692.686.692Zm3.201-.08h6.336a.602.602 0 0 0 .605-.612.598.598 0 0 0-.605-.605H4.766a.601.601 0 0 0-.606.605c0 .339.268.611.606.611Zm-3.2 3.26h.863a.692.692 0 0 0 .691-.686.692.692 0 0 0-.691-.687h-.864a.69.69 0 0 0-.686.687.69.69 0 0 0 .686.686Zm3.2-.08h6.336a.598.598 0 0 0 .605-.606.598.598 0 0 0-.605-.606H4.766a.601.601 0 0 0-.606.606c0 .338.268.606.606.606Zm-3.2 3.266h.863a.692.692 0 0 0 .691-.687.692.692 0 0 0-.691-.686h-.864a.69.69 0 0 0-.686.686.69.69 0 0 0 .686.687Zm3.2-.081h6.336a.601.601 0 0 0 .605-.606.598.598 0 0 0-.605-.606H4.766a.598.598 0 0 0-.606.606c0 .338.268.606.606.606Z"
      fill={props.color}
    />
  </svg>
)

export default IconActivity
