import { IOnboardingInstancePopulated } from '../../../common/models/OnboardingInstance'
import { GET_ONBOARDING_INSTANCE } from '../../../constants/api'
import errorHandler from '../errorHandler'

export default (instanceId: string, token: string) =>
  fetch(GET_ONBOARDING_INSTANCE(instanceId), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(
      async (response: Response): Promise<IOnboardingInstancePopulated> => {
        const result: IOnboardingInstancePopulated = await response.json()
        return {
          ...result,
          chapters: result.chapters.map((chapter) => ({
            ...chapter,
            activities: chapter.activities.map((act) => ({
              ...act,
              lastModified: act.lastModified
                ? new Date(act.lastModified)
                : undefined,
            })),
          })),
        }
      }
    )
