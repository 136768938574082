import Cookies from 'universal-cookie'

export const clearCookies = () => {
  const cookies = new Cookies()

  cookies.remove('auth_token', { path: '/' })
  cookies.remove('auth_token_expiration', { path: '/' })
  cookies.remove('refresh_token', { path: '/' })
}

export const emptyStorage = () => {
  let n = sessionStorage.length
  while (n--) {
    const key = sessionStorage.key(n)
    sessionStorage.removeItem(key || '')
  }
  clearCookies()
}
