import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowRight = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 60 60"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6833 50.2314C25.4992 52.4661 25.4992 56.0893 27.6833 58.324C29.8675 60.5587 33.4087 60.5587 35.5929 58.324L57.6833 35.7223C60.7722 32.562 60.7722 27.438 57.6834 24.2777L35.5929 1.67603C33.4087 -0.558675 29.8675 -0.558675 27.6833 1.67602C25.4992 3.91072 25.4992 7.53389 27.6833 9.76859L40.3316 22.7095C40.9544 23.3468 40.5133 24.4364 39.6325 24.4364L5.4378 24.4364C2.43459 24.4364 0 26.9273 0 30C0 33.0727 2.43459 35.5636 5.4378 35.5636L39.6325 35.5636C40.5133 35.5636 40.9544 36.6532 40.3316 37.2905L27.6833 50.2314Z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowRight
