import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconCheck = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 14 11"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.211 1.242a1.14 1.14 0 00-1.61 0l-6.948 6.92L2.4 5.918a1.14 1.14 0 00-1.611 0 1.133 1.133 0 000 1.606l3.061 3.053a1.14 1.14 0 001.612 0l7.75-7.727a1.133 1.133 0 000-1.607z"
      fill={props.color}
    />
  </svg>
)

export default IconCheck
