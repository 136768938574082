import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconDashboard = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 40 32"
    fill="none"
  >
    <rect width="12" height="32" rx="4" fill={props.color || '#6F3FF3'} />
    <rect
      x="15.5"
      y="1.5"
      width="23"
      height="29"
      rx="3.5"
      stroke={props.color || '#6F3FF3'}
      strokeWidth="3"
    />
  </svg>
)

export default IconDashboard
