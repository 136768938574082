import storeLoginCookies from '../../common/helpers/storeLoginCookies'
import { REFRESH_TOKEN } from '../../constants/api'
import errorHandler from './errorHandler'

export default (currentRefreshToken: string) =>
  fetch(REFRESH_TOKEN, {
    method: 'POST',
    body: JSON.stringify({ token: currentRefreshToken || '' }),
  })
    .then(errorHandler)
    .then(async (rawResponse: Response) => {
      const response = await rawResponse.json()

      const authToken = response.token || ''
      const refreshToken = response.refreshToken || ''

      storeLoginCookies(authToken, refreshToken)

      return {
        authToken,
        refreshToken,
      }
    })
