import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPlus = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 80 80"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 6C46 2.68629 43.3137 0 40 0C36.6863 0 34 2.68629 34 6V30C34 32.2091 32.2091 34 30 34H6C2.68629 34 0 36.6863 0 40C0 43.3137 2.68629 46 6 46H30C32.2091 46 34 47.7909 34 50V74C34 77.3137 36.6863 80 40 80C43.3137 80 46 77.3137 46 74V50C46 47.7909 47.7909 46 50 46H74C77.3137 46 80 43.3137 80 40C80 36.6863 77.3137 34 74 34H50C47.7909 34 46 32.2091 46 30V6Z"
      fill={props.color}
    />
  </svg>
)

export default IconPlus
