import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconDash = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 80 12"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H74C77.3137 0 80 2.68629 80 6C80 9.31371 77.3137 12 74 12H6C2.68629 12 0 9.31371 0 6Z"
      fill={props.color}
    />
  </svg>
)

export default IconDash
