import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconChevronRight = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 35 60"
  >
    <path
      d="M2.00012 50L19.1717 32.8284C20.7338 31.2663 20.7338 28.7337 19.1717 27.1716L2.00012 10C-0.209023 7.79086 -0.209023 4.20913 2.00012 2C4.20926 -0.209144 7.79098 -0.209146 10.0001 1.99999L32.3433 24.3431C35.4675 27.4673 35.4675 32.5326 32.3433 35.6568L10.0001 58C7.79098 60.2091 4.20926 60.2091 2.00012 58C-0.209023 55.7909 -0.20902 52.2091 2.00012 50Z"
      fill={props.color}
    />
  </svg>
)

export default IconChevronRight
