import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconGoogle = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 25 25"
    fill="none"
  >
    <g clipPath="url(#google)">
      <path
        d="M23.99 13.11c0-.79-.07-1.54-.19-2.27H12.5v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82Z"
        fill="#4285F4"
      />
      <path
        d="M12.5 24.84c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96H1.79v3.09c1.97 3.92 6.02 6.62 10.71 6.62Z"
        fill="#34A853"
      />
      <path
        d="M5.77 15.13c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29V7.46H1.79a11.86 11.86 0 0 0 0 10.76l3.98-3.09Z"
        fill="#FBBC05"
      />
      <path
        d="M12.5 5.59c1.77 0 3.35.61 4.6 1.8l3.42-3.42C18.45 2.03 15.74.84 12.5.84c-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="google">
        <path fill="#fff" transform="translate(.5 .84)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default IconGoogle
