import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLibrary = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 30 27"
    fill="none"
  >
    <path
      stroke={props.color}
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 26V6.87M15 26c0-2.423-4.148-6.887-9.981-6.887V1.003C9.815.875 15 5.084 15 6.87M15 26c0-1.53-7.519-2.806-14-2.806V4.32h4.019M15 26c0-2.423 4.044-6.887 9.852-6.887V1.003C20.574 1.003 15 4.727 15 6.87M15 26c0-1.53 7.26-2.806 14-2.806V4.32h-4.148"
    />
  </svg>
)

export default IconLibrary
