import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconExternalLink = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M1.636.364C.933.364.364.934.364 1.636v8.728c0 .702.57 1.272 1.272 1.272h8.727c.703 0 1.273-.57 1.273-1.272V9.1a.846.846 0 0 0-1.69 0 .845.845 0 0 1-.846.845H2.9a.845.845 0 0 1-.846-.845V2.9c0-.467.379-.845.846-.845h.282a.845.845 0 0 0 0-1.691H1.636Zm8.299 6.004c.005.47.39.855.86.86a.828.828 0 0 0 .841-.84V1.634C11.63.97 11.03.371 10.365.364H5.613a.828.828 0 0 0-.841.84c.005.47.39.856.86.861l2.721.031c.134.002.203.165.11.258L4.825 5.99a.827.827 0 0 0 .014 1.17.827.827 0 0 0 1.17.013l3.636-3.636c.093-.093.256-.024.258.11l.03 2.72Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconExternalLink
