import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  className?: string
}

const IconJournal: React.FC<IProps> = (props) => (
  <svg
    className={props.className}
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 28 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.103 17.982c7.697.427 10.533-3.777 10.533-8.668 0-4.89-4.172-8.856-9.318-8.856S0 4.423 0 9.314c0 2.55 1.134 4.85 2.95 6.465 1.163.886 0 4.163 0 4.163s2.886-.19 5.153-1.96Z"
      fill={props.color}
    />
    <path
      d="m8.122 17.631-.132-.007-.103.081c-1.084.847-2.323 1.32-3.301 1.582-.455.121-.85.196-1.14.24l.005-.016c.093-.33.206-.78.282-1.264.075-.48.116-1.01.056-1.488-.06-.467-.224-.945-.615-1.25C1.434 13.955.35 11.753.35 9.314.351 4.634 4.349.81 9.318.81c4.97 0 8.967 3.825 8.967 8.505 0 2.382-.69 4.552-2.273 6.076-1.58 1.521-4.106 2.451-7.89 2.241Z"
      stroke={props.color}
      strokeWidth=".702"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.643 17.09c.948.204 1.921.31 2.886.31 2.37 1.756 5.388 2.366 5.388 2.366s-1.217-3.247 0-4.125C26.814 14.04 28 11.762 28 9.235 28 4.388 23.64.458 18.26.458c-.946 0-1.86.122-2.726.348 2.808 1.895 4.628 4.931 4.628 8.354 0 3.135-1.038 6.03-3.518 7.93Z"
      fill={props.color}
    />
  </svg>
)

export default IconJournal
