import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  secondaryColor?: string
}

const IconSwitch = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 15 10"
    fill="none"
  >
    <path
      d="M4.805 5.632 3.45 4.275v1.083a3.279 3.279 0 0 0 3.278 3.279h1.551a.54.54 0 0 1 0 1.08h-1.55a4.358 4.358 0 0 1-4.359-4.359V4.275L1.012 5.632a.54.54 0 0 1-.763-.764L2.527 2.59a.54.54 0 0 1 .763 0L5.57 4.868a.54.54 0 1 1-.764.764ZM5.358.455a.54.54 0 0 0 0 1.08h2.029a3.279 3.279 0 0 1 3.278 3.278v1.083L9.31 4.54a.54.54 0 0 0-.764.764l2.278 2.278c.211.21.553.21.764 0l2.278-2.278a.54.54 0 0 0-.763-.764l-1.357 1.357V4.813A4.358 4.358 0 0 0 7.387.455H5.358Z"
      fill={props.color}
    />
  </svg>
)

export default IconSwitch
