import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconReviews = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 31 22"
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M17.964 17.951c1.022.22 2.073.335 3.115.335 2.557 1.894 5.814 2.553 5.814 2.553s-1.313-3.505 0-4.452c2.049-1.728 3.328-4.187 3.328-6.915C30.221 4.241 25.514 0 19.707 0a11.6 11.6 0 0 0-2.941.376c3.03 2.044 4.994 5.322 4.994 9.016 0 3.384-1.12 6.508-3.796 8.559Z"
      clipRule="evenodd"
    />
    <mask id="reviewIconMask" fill="#fff">
      <path
        fillRule="evenodd"
        d="M20.571 9.558c0 5.28-3.06 9.817-11.369 9.355-2.446 1.912-5.561 2.116-5.561 2.116s1.256-3.537 0-4.493C1.68 14.792.457 12.311.457 9.558.457 4.28 4.96 0 10.514 0c5.555 0 10.057 4.28 10.057 9.558ZM14.914 5.98c.382-.38 1-.38 1.381 0a.971.971 0 0 1 0 1.377L9.653 13.98c-.382.38-1 .38-1.381 0l-2.625-2.617a.971.971 0 0 1 0-1.377c.382-.381 1-.381 1.381 0L8.96 11.91l5.955-5.932Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M20.571 9.558c0 5.28-3.06 9.817-11.369 9.355-2.446 1.912-5.561 2.116-5.561 2.116s1.256-3.537 0-4.493C1.68 14.792.457 12.311.457 9.558.457 4.28 4.96 0 10.514 0c5.555 0 10.057 4.28 10.057 9.558ZM14.914 5.98c.382-.38 1-.38 1.381 0a.971.971 0 0 1 0 1.377L9.653 13.98c-.382.38-1 .38-1.381 0l-2.625-2.617a.971.971 0 0 1 0-1.377c.382-.381 1-.381 1.381 0L8.96 11.91l5.955-5.932Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      d="m9.202 18.913.042-.756-.284-.016-.224.175.466.597ZM3.641 21.03l-.714-.254-.386 1.085 1.149-.075-.05-.756Zm0-4.493-.504.566.022.02.023.017.459-.603ZM16.295 5.98l-.535.537.535-.537Zm-1.38 0 .534.537-.535-.537Zm1.38 1.377-.535-.536.535.536ZM9.653 13.98l-.535-.537.535.537Zm-1.381 0-.535.536.535-.536Zm-2.625-2.617.535-.537-.535.537Zm0-1.377.535.536-.535-.536Zm1.381 0 .535-.537-.535.537ZM8.96 11.91l-.535.537.535.533.535-.533-.535-.537Zm.201 7.759c4.294.238 7.368-.81 9.368-2.736 1.994-1.92 2.801-4.597 2.801-7.376h-1.515c0 2.501-.724 4.732-2.337 6.284-1.608 1.548-4.218 2.537-8.233 2.315L9.16 19.67Zm-5.52 1.359.05.756h.006l.01-.001.033-.003a7.26 7.26 0 0 0 .538-.064c.353-.053.848-.143 1.423-.297 1.142-.306 2.638-.87 3.969-1.91l-.933-1.194c-1.116.872-2.401 1.365-3.428 1.64a11.35 11.35 0 0 1-1.607.306l-.088.009-.02.001H3.59l.05.757Zm-.458-3.89c.079.06.189.218.237.597.046.366.017.818-.056 1.288a10.69 10.69 0 0 1-.435 1.745l-.001.006.714.254.714.253v-.002l.001-.003.004-.01a4.523 4.523 0 0 0 .053-.159 12.205 12.205 0 0 0 .447-1.85c.084-.534.134-1.145.062-1.712-.07-.553-.273-1.195-.822-1.613l-.918 1.206ZM-.301 9.56c0 2.984 1.329 5.666 3.438 7.543l1.007-1.132c-1.81-1.611-2.93-3.89-2.93-6.412H-.3ZM10.514-.759C4.578-.758-.3 3.825-.3 9.558h1.516c0-4.824 4.127-8.8 9.3-8.8V-.758ZM21.33 9.558c0-5.733-4.879-10.316-10.815-10.316V.758c5.173 0 9.3 3.976 9.3 8.8h1.515ZM16.83 5.443a1.736 1.736 0 0 0-2.45 0l1.07 1.073a.22.22 0 0 1 .31 0l1.07-1.073Zm0 2.45a1.729 1.729 0 0 0 0-2.45l-1.07 1.073a.213.213 0 0 1 0 .304l1.07 1.073Zm-6.642 6.623 6.642-6.623-1.07-1.073-6.642 6.623 1.07 1.073Zm-2.451 0a1.736 1.736 0 0 0 2.45 0l-1.07-1.073a.22.22 0 0 1-.31 0l-1.07 1.073ZM5.112 11.9l2.625 2.617 1.07-1.073-2.625-2.617-1.07 1.073Zm0-2.45a1.729 1.729 0 0 0 0 2.45l1.07-1.073a.213.213 0 0 1 0-.304L5.112 9.45Zm2.451 0a1.736 1.736 0 0 0-2.45 0l1.07 1.073a.22.22 0 0 1 .31 0l1.07-1.073Zm1.931 1.925L7.564 9.45l-1.07 1.073 1.93 1.925 1.07-1.073Zm4.885-5.932-5.955 5.932 1.07 1.074 5.955-5.932-1.07-1.074Z"
      mask="url(#reviewIconMask)"
    />
  </svg>
)

export default IconReviews
