import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconHide = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 88 88"
    fill="none"
  >
    <path
      d="M71.656 15.988a3.372 3.372 0 00-4.691-.076l-8.772 8.223a44.044 44.044 0 00-14.143-2.33C18.82 21.805 4 43.642 4 43.642s6.427 9.837 17.153 17.127l-7.334 7.329a3.348 3.348 0 004.712 4.759L71.632 20.78a3.372 3.372 0 00.023-4.792zM49.293 32.942c-10.96-4.843-22.27 6.36-17.55 17.353l-5.753 5.69c-6.047-3.866-10.75-8.833-13.533-12.2 4.976-5.493 16.056-15.313 31.593-15.313a37 37 0 018.7 1.05l-3.457 3.42zm-9.61 24.77L56.65 41.07c3.39 10.37-6.677 20.223-16.967 16.643zM84 43.642s-14.173 24.83-39.95 24.83c-4.597 0-8.873-.97-12.837-2.456l5.38-5.277c2.384.643 4.86 1.067 7.457 1.067 15.97 0 27.013-11.757 31.68-17.88-2.43-2.74-6.52-6.634-11.957-9.84l4.964-4.867C78.677 35.552 84 43.642 84 43.642z"
      fill={props.color}
    />
  </svg>
)

export default IconHide
