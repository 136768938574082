import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconSettings = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 78 80"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.53 0.026139C43.7267 -0.223243 42.2705 1.34981 41.857 3.12269C40.9266 7.11156 37.7957 10.4189 33.5302 11.3683C29.2979 12.3103 25.0926 10.6764 22.5516 7.50777C21.411 6.08536 19.4138 5.29562 17.8951 6.30449C16.0173 7.55192 14.2509 8.95375 12.6141 10.4919C11.2864 11.7394 11.5971 13.8622 12.7189 15.2978C15.2328 18.5148 15.849 23.0108 13.9305 26.9225C12.0164 30.8251 8.09811 33.0889 4.02559 33.0812C2.20207 33.0778 0.334376 34.1385 0.168166 35.9545C0.0568741 37.1704 0 38.402 0 39.647C0 40.7823 0.0473036 41.9067 0.140047 43.0181C0.291688 44.8353 2.15071 45.9111 3.9742 45.9225C8.044 45.9478 11.9419 48.2401 13.8257 52.1545C15.7163 56.0826 15.0649 60.5768 12.5235 63.7754C11.3901 65.2019 11.0624 67.3222 12.3801 68.5804C14.0337 70.1595 15.8225 71.5982 17.7274 72.8774C19.241 73.8939 21.2421 73.1142 22.3899 71.6976C24.9532 68.5339 29.1807 66.9223 33.4154 67.8988C37.6637 68.8784 40.7648 72.1963 41.6729 76.1798C42.0775 77.9548 43.5259 79.5352 45.3305 79.2949C47.7155 78.9773 50.0341 78.4495 52.267 77.7309C54.0022 77.1724 54.6285 75.1186 54.2268 73.3406C53.3294 69.3683 54.682 65.05 58.0719 62.3293C61.4766 59.5969 66.0097 59.2195 69.7024 60.9815C71.3464 61.766 73.4858 61.6101 74.416 60.044C75.5869 58.0726 76.5932 55.9921 77.416 53.8213C78.062 52.1167 76.8476 50.346 75.2074 49.5508C71.5333 47.7696 69 44.0041 69 39.647C69 35.2898 71.5333 31.5244 75.2074 29.7431C76.8476 28.9479 78.062 27.1772 77.416 25.4727C76.63 23.399 75.6766 21.4078 74.5723 19.5154C73.6542 17.9421 71.516 17.7699 69.8661 18.5416C66.1614 20.2745 61.6332 19.8634 58.2498 17.1072C54.8786 14.361 53.5577 10.0307 54.486 6.06382C54.9014 4.28904 54.291 2.23066 52.5603 1.65874C50.2994 0.911625 47.9493 0.360702 45.53 0.026139ZM39.5 52C46.4036 52 52 46.4036 52 39.5C52 32.5964 46.4036 27 39.5 27C32.5964 27 27 32.5964 27 39.5C27 46.4036 32.5964 52 39.5 52Z"
      fill={props.color}
    />
  </svg>
)

export default IconSettings
