import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconFeedback = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 31 24"
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M30.114 9.558c0 5.28-3.06 9.817-11.369 9.355-2.446 1.912-5.561 2.116-5.561 2.116s1.256-3.537 0-4.493C11.224 14.792 10 12.311 10 9.558 10 4.28 14.503 0 20.057 0c5.555 0 10.057 4.28 10.057 9.558ZM20 4.91c.422 0 .764.342.764.764v3.054c0 .281.227.51.509.51h3.054a.764.764 0 0 1 0 1.527h-3.054a.51.51 0 0 0-.51.509v3.054a.764.764 0 0 1-1.527 0v-3.054a.51.51 0 0 0-.509-.51h-3.054a.764.764 0 0 1 0-1.527h3.054a.51.51 0 0 0 .51-.509V5.673c0-.422.341-.764.763-.764Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M13.553 17.022c.285.648.203 1.62.045 2.444-.166.862-.414 1.563-.414 1.563s.68-.045 1.655-.288a11.639 11.639 0 0 0 2.284-.822c-.755-1.116-1.975-2.174-3.57-2.897Zm-2.726-.807a11.3 11.3 0 0 0 1.36 1.442l.006.006c.01.03.022.084.032.166.034.267.016.642-.052 1.08a9.968 9.968 0 0 1-.396 1.596l-.007.02v.003l-.763 2.146 2.274-.149H13.29l.014-.001.04-.003.133-.013c.11-.011.263-.03.451-.057.377-.056.9-.152 1.506-.314a13.029 13.029 0 0 0 2.364-.878c.133.402.202.797.202 1.172 0 .994-.616 1.569-2.43 1.569H2.43C.616 24 0 23.425 0 22.431c0-2.677 3.492-6.357 9-6.357.636 0 1.246.05 1.827.14Zm.167-2.508c1.374-.772 2.326-2.32 2.326-4.148 0-1.793-.975-3.317-2.368-4.065A9.13 9.13 0 0 0 10 9.558c0 1.487.357 2.895.994 4.15ZM9.507 5.031A10.618 10.618 0 0 0 8.5 9.558c0 1.66.382 3.23 1.062 4.636-.185.026-.372.04-.562.04-2.356 0-4.309-2.05-4.309-4.664C4.681 7.029 6.665 5 9 5c.171 0 .34.01.507.031Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconFeedback
