import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconClose = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 60 60"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.8285 27.1716L58.0001 10C60.2093 7.79086 60.2093 4.20913 58.0001 1.99999C55.791 -0.209145 52.2093 -0.209145 50.0001 2L32.8285 19.1716C31.2664 20.7337 28.7338 20.7337 27.1717 19.1716L10.0001 1.99999C7.79098 -0.209146 4.20926 -0.209144 2.00012 2C-0.209023 4.20914 -0.209023 7.79086 2.00012 10L19.1717 27.1716C20.7338 28.7337 20.7338 31.2663 19.1717 32.8284L2.00012 50C-0.20902 52.2091 -0.209023 55.7909 2.00012 58C4.20926 60.2091 7.79098 60.2091 10.0001 58L27.1717 40.8284C28.7338 39.2663 31.2664 39.2663 32.8285 40.8284L50.0001 58C52.2093 60.2091 55.791 60.2091 58.0001 58C60.2093 55.7909 60.2093 52.2091 58.0001 50L40.8285 32.8284C39.2664 31.2663 39.2664 28.7337 40.8285 27.1716Z"
      fill={props.color}
    />
  </svg>
)

export default IconClose
