import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconFile = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 26 34"
    fill="none"
  >
    <path
      fill={props.color}
      d="M4.858 8.775c0-.478.254-.866.568-.866h7.5c.314 0 .568.388.568.866v.866c0 .478-.254.866-.568.866h-7.5c-.314 0-.568-.388-.568-.866v-.866ZM4.858 13.97c0-.479.254-.866.568-.866h13.352c.314 0 .568.388.568.866v.865c0 .479-.254.866-.568.866H5.426c-.314 0-.568-.387-.568-.866v-.865ZM4.858 19.165c0-.479.254-.866.568-.866h14.773c.313 0 .568.387.568.866v.865c0 .479-.255.866-.568.866H5.426c-.314 0-.568-.387-.568-.866v-.866ZM4.858 24.36c0-.479.254-.866.568-.866h7.386c.314 0 .568.387.568.865v.866c0 .478-.254.866-.568.866H5.426c-.314 0-.568-.388-.568-.866v-.866Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M3.91.182A3.636 3.636 0 0 0 .272 3.818v26.364a3.636 3.636 0 0 0 3.636 3.636h18.182a3.636 3.636 0 0 0 3.636-3.636V8.67a1.82 1.82 0 0 0-.54-1.295l-.434-.429-.01-.01c-2.007-1.988-4.89-4.843-6.714-6.363a1.722 1.722 0 0 0-1.107-.391H3.909Zm12.22 2.077h.052v5.65c0 1.004.814 1.818 1.818 1.818h5.65v20.455a1.56 1.56 0 0 1-1.56 1.559H3.91a1.56 1.56 0 0 1-1.56-1.56V3.819c0-.86.698-1.559 1.56-1.559h12.22Zm6.631 5.65c-1.055-1.018-2.733-2.622-4.053-3.884l-.001-.001L18 3.348V6.09c0 1.004.814 1.818 1.818 1.818h2.943Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconFile
