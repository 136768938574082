import {
  GoalChatMessageType,
  IGoalMessage,
  PdpStatus,
  IGoal,
  IPdp,
  IPdpPure,
} from '../../../common/models/Pdp'
import { ICompany } from '../../../common/models/Company'
import { GET_PDP_BY_USER } from '../../../constants/api'
import errorHandler from '../errorHandler'
import { generateRandomNotificationNumber } from '../../../common/helpers/randomNotifications.helper'

export const loadPersonalPdpService = (
  memberId: string,
  termNumber: number | 'latest',
  token: string,
  company: ICompany
) =>
  fetch(GET_PDP_BY_USER(memberId, termNumber), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(
      async (
        getUserDetails: Response
      ): Promise<{ savedPdp: IPdp | undefined }> => {
        const savedPdp: IPdpPure = await getUserDetails.json()

        if (!Object.keys(savedPdp).length) {
          return { savedPdp: undefined }
        }

        if (!savedPdp || (savedPdp instanceof Array && savedPdp.length === 0)) {
          return {
            savedPdp: {
              goals: [],
              memberId,
              dateCreated: new Date(),
              status: PdpStatus.DRAFTING,
              termNumber: company.state.currentTermNumber,
            },
          }
        }

        const adaptedPdp = adaptPurePdpToFrontendPdp(savedPdp)

        return { savedPdp: adaptedPdp }
      }
    )

export const setDatesToPurePdp = (savedPdp: IPdpPure): IPdpPure => {
  const personalGoals = savedPdp.goals
  // Start by fixing all dates in the input to be dates instead of strings
  personalGoals.forEach((goal) => {
    goal.dateCreated = new Date(goal.dateCreated)
    goal.pinnedDate = goal.pinnedDate ? new Date(goal.pinnedDate) : undefined
    goal.submissions.forEach((submission) => {
      submission.dateCreated = new Date(submission.dateCreated)
    })
    goal.messages?.forEach((message) => {
      message.dateCreated = new Date(message.dateCreated)
    })
  })
  savedPdp.dateCreated = savedPdp.dateCreated
    ? new Date(savedPdp.dateCreated)
    : savedPdp.dateCreated

  return savedPdp
}
export const adaptPurePdpToFrontendPdp = (savedPdp: IPdpPure): IPdp => {
  const personalGoals = savedPdp.goals
  setDatesToPurePdp(savedPdp)

  // Update the personal goals in the following way
  // 1. ( !!! not done anymore ) find the reviewer in each of the feedbacks
  // 2. find the "main" reviewer of the PDP
  // 3. Calculate the number of notifications
  // 4. Add the creation event in the journal
  const personalGoalsWithReviewer: IGoal[] = personalGoals.map((goal) => {
    const journals: IGoalMessage[] = [...(goal.messages || [])].reverse()

    // Add the creation event in the journal
    const lastSubmission = goal.submissions.length && goal.submissions[0]
    if (lastSubmission) {
      journals.push({
        notificationId: generateRandomNotificationNumber(),
        dateCreated: lastSubmission.dateCreated,
        messageType: GoalChatMessageType.GOAL_CREATED,
        senderId: savedPdp.memberId,
        termNumber: savedPdp.termNumber,
      })
    }

    return {
      ...goal,
      journals: journals.reverse(),
    }
  })

  return { ...savedPdp, goals: personalGoalsWithReviewer }
}
