import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

// Styles
import { defaultTextColor } from '../styles/colors'
import {
  applyTextStyle,
  defaultTextStyle,
  markDownStyles,
} from '../styles/typography'
import { fastTransition } from '../styles/effects'

// Types
import { AnyColor } from '../common/models/colors'
import { TextStyleType, TextTagType } from '../common/models/typography'

interface IProps {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  tag?: TextTagType
  color?: AnyColor
  style?: TextStyleType
  className?: string
  children?: React.ReactNode
  textAlign?: 'left' | 'right' | 'center'
}

export const Text = (props: IProps) => {
  const tag = props.tag || 'p'
  const color = props.color || defaultTextColor
  const textStyle = props.style || defaultTextStyle

  const marginTop = props.marginTop || 0
  const marginRight = props.marginRight || 0
  const marginBottom = props.marginBottom || 0
  const marginLeft = props.marginLeft || 0
  const textAlign = props.textAlign || 'left'

  return (
    <StyledText
      className={props.className}
      as={tag}
      color={color}
      textStyle={textStyle}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      textAlign={textAlign}
    >
      {props.children}
    </StyledText>
  )
}

export const MarkDownText = (props: IProps) => {
  const tag = 'div' // should always be div for markdown
  const color = props.color || defaultTextColor
  const textStyle = props.style || defaultTextStyle

  const marginTop = props.marginTop || 0
  const marginRight = props.marginRight || 0
  const marginBottom = props.marginBottom || 0
  const marginLeft = props.marginLeft || 0
  const textAlign = props.textAlign || 'left'

  const markdown = props.children && props.children.toString()
  if (!markdown) return null
  return (
    <StyledMarkDownText
      className={props.className}
      as={tag}
      color={color}
      textStyle={textStyle}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      textAlign={textAlign}
    >
      <ReactMarkdown children={markdown} />
    </StyledMarkDownText>
  )
}

const StyledText = styled.span<{
  textStyle: TextStyleType
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  textAlign?: string
}>`
  margin-top: ${(props) => props.marginTop}px;
  margin-right: ${(props) => props.marginRight}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-left: ${(props) => props.marginLeft}px;
  color: ${(props) => props.theme.colors[props.color!]};
  ${(props) => applyTextStyle(props.textStyle)}
  text-align: ${(props) => props.textAlign};
  transition: color ${fastTransition};
`

const StyledMarkDownText = styled.div<{
  textStyle: TextStyleType
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  textAlign?: string
}>`
  ${markDownStyles()};
  margin-top: ${(props) => props.marginTop}px;
  margin-right: ${(props) => props.marginRight}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-left: ${(props) => props.marginLeft}px;
  color: ${(props) => props.theme.colors[props.color!]};
  ${(props) => applyTextStyle(props.textStyle)}
  text-align: ${(props) => props.textAlign}
`

export default Text
