import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLongArrow = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 68"
    fill="none"
  >
    <path
      fill={props.color}
      d="M13.5 2a1.5 1.5 0 0 0-3 0h3Zm-2.56 65.06a1.5 1.5 0 0 0 2.12 0l9.547-9.545a1.5 1.5 0 1 0-2.122-2.122L12 63.88l-8.485-8.486a1.5 1.5 0 1 0-2.122 2.122l9.546 9.546ZM10.5 2v64h3V2h-3Z"
    />
  </svg>
)

export default IconLongArrow
