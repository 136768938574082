import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  secondaryColor?: string
}

const IconTeam = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 34 34"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.675 21.552h-3.31a9.522 9.522 0 01-7.028 5.563c.482.261.924.562 1.32.891.54.448 1.028.979 1.416 1.564a12.645 12.645 0 007.602-8.018zm-5.431-10.637a5.512 5.512 0 01-.155-1.29 5.325 5.325 0 01.547-2.364 12.553 12.553 0 00-6.992-2.115c-2.429 0-4.697.687-6.622 1.877.62.3 1.18.662 1.67 1.07.365.303.706.643 1.008 1.012a9.472 9.472 0 013.944-.854 9.475 9.475 0 016.6 2.664zM4.22 15.578h3.168c-.164.7-.25 1.43-.25 2.18 0 2.207.75 4.238 2.012 5.851a5.49 5.49 0 001.086 3.233c-.51.231-.983.505-1.412.812a12.59 12.59 0 01-4.792-9.896c0-.743.065-1.472.188-2.18z"
      fill={props.secondaryColor || props.color}
    />
    <path
      d="M16.892 23.554c0 1.807-1.309 3.22-2.879 3.22s-2.872-1.413-2.872-3.213c-.007-1.75 1.316-3.148 2.872-3.148 1.563 0 2.879 1.383 2.879 3.14zM20.011 32.42c0 .684-.41 1.08-1.62 1.08H9.635c-1.21 0-1.62-.396-1.62-1.08 0-1.844 2.327-4.38 5.998-4.38 3.664 0 5.998 2.536 5.998 4.38zM8.926 3.64c0 1.808-1.308 3.22-2.878 3.22S3.176 5.448 3.176 3.647C3.169 1.897 4.492.5 6.048.5 7.61.5 8.926 1.883 8.926 3.64zM12.046 12.506c0 .684-.41 1.08-1.62 1.08H1.669c-1.21 0-1.62-.396-1.62-1.08 0-1.844 2.328-4.379 5.999-4.379 3.663 0 5.998 2.535 5.998 4.38zM30.831 9.614c0 1.808-1.308 3.22-2.879 3.22-1.57 0-2.871-1.412-2.871-3.213-.007-1.75 1.315-3.147 2.871-3.147 1.564 0 2.88 1.383 2.88 3.14zM33.95 18.48c0 .684-.41 1.08-1.62 1.08h-8.756c-1.21 0-1.62-.395-1.62-1.08 0-1.844 2.328-4.379 5.998-4.379 3.664 0 5.998 2.535 5.998 4.38z"
      fill={props.color}
    />
  </svg>
)

export default IconTeam
