import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  textColor?: string
  className?: string
}

const IconInfo: React.FC<IProps> = (props) => (
  <svg
    className={props.className}
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 56 56"
    fill="none"
  >
    <circle cx="28" cy="28" r="28" fill={props.color} />
    <path
      d="M33.102 15.265a3.266 3.266 0 01-6.53 0 3.267 3.267 0 016.53 0zM24 23.102a2 2 0 00-2 2v1.224a2 2 0 002 2h3.224V42a2 2 0 002 2h1.225a2 2 0 002-2V25.102a2 2 0 00-2-2H24z"
      fill={props.textColor || '#FFF'}
    />
  </svg>
)

export default IconInfo
