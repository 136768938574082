import {
  ICompanyFeedbackAggregation,
  IFeedbackMemberResultAggregation,
} from '../../../common/models/Feedback'
import { GET_COMPANY_FEEDBACK_AGGREGATIONS } from '../../../constants/api'
import errorHandler from '../errorHandler'

export default (token: string) =>
  fetch(GET_COMPANY_FEEDBACK_AGGREGATIONS(), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(
      async (pdpsResponse: Response): Promise<ICompanyFeedbackAggregation> => {
        const allAggregations: IFeedbackMemberResultAggregation[] = await pdpsResponse.json()
        const feedbackResultsPerMember: ICompanyFeedbackAggregation = new Map()

        allAggregations
          .filter((feedback) => !feedback.external)
          .forEach((feedback) => {
            feedbackResultsPerMember.set(feedback.memberId, feedback.results)
          })

        return feedbackResultsPerMember
      }
    )
