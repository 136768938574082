import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

// Components
import Icon from './icon/Icon'
import Text from './Text'

// Styles
import { shadows } from '../styles/effects'
import { mqFrom } from '../styles/mediaQueries'

interface IProps {
  label: string | React.ReactNode
}

const Notification: React.FC<IProps> = ({ label }) => {
  const themeContext = useContext(ThemeContext)
  return (
    <Container>
      <Icon
        type="Notification"
        size={22}
        color={themeContext.colors.lightest}
      />
      <Text color="lightest">{label}</Text>
    </Container>
  )
}

export default Notification

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 12px;
  box-shadow: ${shadows.depthFive};
  position: fixed;
  z-index: 1500;
  top: 72px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;

  ${mqFrom.S`
    padding: 12px 24px;
  `}
`
