import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconList = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 26 18"
    fill="none"
  >
    <path
      fill={props.color}
      d="M2.272 3.51h1.942c.852 0 1.556-.704 1.556-1.556 0-.852-.704-1.545-1.556-1.545H2.272c-.84 0-1.545.693-1.545 1.545S1.432 3.51 2.272 3.51Zm7.201-.182h14.255c.761 0 1.363-.602 1.363-1.374 0-.761-.59-1.363-1.363-1.363H9.473c-.76 0-1.363.602-1.363 1.363s.602 1.374 1.363 1.374Zm-7.2 7.338h1.941c.852 0 1.556-.693 1.556-1.545s-.704-1.545-1.556-1.545H2.272c-.84 0-1.545.693-1.545 1.545s.705 1.545 1.545 1.545Zm7.2-.182h14.255c.761 0 1.363-.59 1.363-1.363 0-.76-.59-1.363-1.363-1.363H9.473c-.76 0-1.363.602-1.363 1.363s.602 1.363 1.363 1.363Zm-7.2 7.35h1.941c.852 0 1.556-.694 1.556-1.546 0-.851-.704-1.544-1.556-1.544H2.272c-.84 0-1.545.693-1.545 1.544 0 .852.705 1.545 1.545 1.545Zm7.2-.183h14.255c.761 0 1.363-.602 1.363-1.363 0-.76-.59-1.363-1.363-1.363H9.473c-.76 0-1.363.591-1.363 1.363 0 .761.602 1.363 1.363 1.363Z"
    />
  </svg>
)

export default IconList
