import ReactGA from 'react-ga'
import { isClient } from './getRenderPlatform'

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
const ANONYMIZE_DATA = process.env.REACT_APP_ANONYMIZE_GOOGLE_ANALYTICS

const trackPageView = (location: Location) => {
  ReactGA.pageview(location.pathname)
}

const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean
) => {
  const data: ReactGA.EventArgs = {
    action,
    category,
    label,
    nonInteraction,
    value,
  }

  ReactGA.event(data)
}

const initGa = (history: any) => {
  if (isClient() && !(window as any).ga && GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
      titleCase: false,
    })
    ReactGA.set({ anonymizeIp: ANONYMIZE_DATA })
    trackPageView(history.location)
    history.listen(trackPageView)
  }
}

export { trackEvent, trackPageView }
export default initGa
