import { Breakpoint } from '../../../styles/responsive'
import { textStyles } from '../../../styles/typography'

/**
 * Define the possible values for CSS 'font-weight' property and give them
 * names
 */
export enum FontWeight {
  Thin = 100,
  ExtraLight = 200,
  Light = 300,
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Heavy = 900,
}

type IFontStyle = 'initial' | 'normal' | 'italic'

/**
 * Define CSS rules that generally apply to text elements
 */
export interface ITextRules {
  fontSize: number // In px
  lineHeight: number // In px
  fontFamily?: string
  fontWeight?: FontWeight
  textTransform?: TextTransformType
  fontStyle?: IFontStyle
}

/**
 * Define mobile text rules and optionally, desktop ones
 */
export interface ITextStyle {
  mobile: ITextRules
  desktop?: ITextRules
  breakpoint?: Breakpoint
}

export type TextStyleType = keyof typeof textStyles

export type TextTagType =
  | 'div'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'strong'
  | 'em'
  | 'span'
  | 'label'

/**
 * Define the possible values for the CSS 'text-transform' property
 */
export enum TextTransformType {
  Lowercase = 'lowercase',
  Uppercase = 'uppercase',
  Capitalize = 'capitalize',
}

export interface ITextStyleConfig {
  [name: string]: ITextStyle
}
