import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  secondaryColor?: string
}

const IconCompany = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 30 26"
    fill="none"
  >
    <path
      d="M20.477 25.702h6.435c1.588 0 2.534-1.015 2.534-2.697V8.926c0-1.692-.946-2.708-2.534-2.708h-5.676v16.788c0 .993-.257 1.904-.759 2.697zm3.515-13.38v-1.844c0-.233.152-.397.386-.397h1.903c.234 0 .386.164.386.397v1.845c0 .245-.152.409-.386.409h-1.903c-.234 0-.386-.164-.386-.41zm0 4.566v-1.845c0-.245.152-.409.386-.409h1.903c.234 0 .386.164.386.41v1.844c0 .233-.152.397-.386.397h-1.903c-.234 0-.386-.164-.386-.397zm0 4.553v-1.845c0-.245.152-.408.386-.408h1.903c.234 0 .386.163.386.408v1.845c0 .245-.152.397-.386.397h-1.903c-.234 0-.386-.152-.386-.397z"
      fill={props.secondaryColor || props.color}
    />
    <path
      d="M.554 23.006c0 1.68.934 2.696 2.523 2.696h13.827c1.588 0 2.534-1.015 2.534-2.697V2.995c0-1.682-.946-2.697-2.534-2.697H3.077C1.488.298.554 1.313.554 2.995v20.01zM5.517 6.894V4.676c0-.292.187-.49.468-.49h2.382c.28 0 .479.198.479.49v2.218c0 .292-.199.49-.48.49H5.986c-.28 0-.468-.198-.468-.49zm5.7 0V4.676c0-.292.186-.49.466-.49h2.383c.28 0 .467.198.467.49v2.218c0 .292-.187.49-.467.49h-2.383c-.28 0-.467-.198-.467-.49zm-5.7 4.775V9.451c0-.292.187-.49.468-.49h2.382c.28 0 .479.198.479.49v2.218c0 .292-.199.49-.48.49H5.986c-.28 0-.468-.198-.468-.49zm5.7 0V9.451c0-.292.186-.49.466-.49h2.383c.28 0 .467.198.467.49v2.218c0 .292-.187.49-.467.49h-2.383c-.28 0-.467-.198-.467-.49zm-5.7 4.775v-2.218c0-.292.187-.49.468-.49h2.382c.28 0 .479.198.479.49v2.218c0 .292-.199.49-.48.49H5.986c-.28 0-.468-.198-.468-.49zm5.7 0v-2.218c0-.292.186-.49.466-.49h2.383c.28 0 .467.198.467.49v2.218c0 .292-.187.49-.467.49h-2.383c-.28 0-.467-.198-.467-.49zm-5.443 7.017V19.9c0-.56.374-.91.923-.91h6.575c.654 0 1.004.35 1.004.91v3.56H5.774z"
      fill={props.color}
    />
  </svg>
)

export default IconCompany
