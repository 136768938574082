import React from 'react'
import styled from 'styled-components'

// Styles
import { mqFrom } from '../../styles/mediaQueries'
import { GridCol, GridRow } from '../grid'

const MainSkeleton = () => (
  <>
    <StyledNavigationBar></StyledNavigationBar>
    <StyledTopHalf>
      <PageWrapper>
        <GridRow alignType="center">
          <GridCol xs={12}>
            <StyledNavLinksWrapper>
              <StyledNavLink></StyledNavLink>
              <StyledNavLink></StyledNavLink>
              <StyledNavLink></StyledNavLink>
              <StyledNavLink></StyledNavLink>
            </StyledNavLinksWrapper>
            <PurpleLine></PurpleLine>
          </GridCol>
        </GridRow>
      </PageWrapper>
    </StyledTopHalf>
    <StyledBottomHalf>
      <PageWrapper adjustHeight>
        <GridRow alignType="center">
          <GridCol xs={12}>
            <StyledTitle></StyledTitle>
            <Box></Box>
          </GridCol>
        </GridRow>
      </PageWrapper>
    </StyledBottomHalf>
  </>
)

const PageWrapper = styled.div<{ adjustHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: ${({ adjustHeight }) => (adjustHeight ? 'auto' : '100%')};
  padding: 0 16px;
  box-sizing: content-box;

  ${mqFrom.M`
    padding: 0 48px;
  `}

  ${mqFrom.L`
    padding: 0 64px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1184px;
  `}
`
const StyledNavigationBar = styled.nav`
  z-index: 1000;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #d1c8ea;
  height: 56px;
`

const StyledTopHalf = styled.div`
  height: 480px;
  background: #efedf8;
  width: 100vw;
`

const StyledNavLinksWrapper = styled.div`
  display: flex;
  gap: 28px;
  justify-content: start;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledNavLink = styled.div`
  min-width: 90px;
  height: 18px;
  background: #e2ddf3;
  border-radius: 3rem;
  margin: 32px 0;
`

const PurpleLine = styled.div`
  width: 100%;
  height: 2px;
  background: #ccc5e7;
`

const StyledBottomHalf = styled.div`
  height: 60vh;
  width: 100vw;
  background: #f8f5fe;
`

const StyledTitle = styled(StyledNavLink)`
  height: 31px;
  max-width: 60%;
  margin-left: 0;

  ${mqFrom.M`
    max-width: 33%;
  `};
`

const Box = styled.div`
  width: 100%;
  height: 40vh;
  background: #efedf8;
  border-radius: 12px;
`

export default MainSkeleton
