import { ICompanyOverview } from './../../common/models/Company'
import { IUser } from '../../common/models/User'
import { AUTH_USER_DETAILS } from '../../constants/api'
import errorHandler from './errorHandler'

export default (token: string, slug?: string) =>
  fetch(AUTH_USER_DETAILS(slug), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(async (getUserDetails: Response) => {
      const userDetailsResponse = await getUserDetails.json()

      const user = userDetailsResponse.user as IUser
      user.lastName = user.lastName || ''
      user.companies =
        (userDetailsResponse.companies as ICompanyOverview[]) || []
      user.selectedCompany = userDetailsResponse.currentCompany

      return { user }
    })
