import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

// Styles
import { hsla } from '../styles/colors'
import { shadows } from '../styles/effects'
import { mqTo, sizes } from '../styles/mediaQueries'

// Components
import Link from './Link'
import Text from './Text'
import Icon from './icon/Icon'

// Types
import { IAlertPopup } from '../common/models/IAlertPopup'
interface IProps {
  className?: string
  content: IAlertPopup
  actionButton?: string
  actionButtonDisabled?: boolean
  onSubmit?: () => void
  dismissButton?: string
  onDismiss?: () => void
  wideVersion?: boolean
}

const DialogScreen = ({
  className,
  content,
  onSubmit,
  actionButton,
  actionButtonDisabled,
  dismissButton,
  onDismiss,
  wideVersion,
}: IProps) => {
  const themeContext = useContext(ThemeContext)
  const { t } = useTranslation()

  const onClickAction =
    content.onDismiss || onDismiss || content.onSubmit || onSubmit

  return content.variant === 'purple' ? (
    <Container className={className}>
      <TransparentContent wideVersion={!!wideVersion}>
        <TitleContainer>
          <Text style="displayL" color="lightest" marginBottom={8}>
            {content.title}
          </Text>
          {onClickAction && (
            <StyledIcon
              onClick={onClickAction}
              type="Close"
              color={themeContext.colors.lightest}
              size={32}
            />
          )}
        </TitleContainer>
        <Text color="lightest" marginBottom={24}>
          {content.subtitle}
        </Text>
        <InnerContent>{content.content}</InnerContent>
      </TransparentContent>
    </Container>
  ) : (
    <Container className={className}>
      <Content wideVersion={!!wideVersion}>
        {content.subtitle && (
          <Text
            textAlign="center"
            marginBottom={8}
            style="subtitle"
            color="primary"
          >
            {content.subtitle}
          </Text>
        )}
        {content.title && (
          <Text marginBottom={24} textAlign="center" style="displayM">
            {content.title}
          </Text>
        )}
        <InnerContent withOverflow>{content.content}</InnerContent>
        <DialogScreenActions wideVersion={!!wideVersion}>
          {(content.onDismiss || onDismiss) && (
            <DialogScreenOnDismiss
              disableUnderline={true}
              caption={t(
                content.dismissButton ||
                  dismissButton ||
                  'general.actions.cancel'
              )}
              color="text"
              onClick={content.onDismiss || onDismiss}
            />
          )}
          <DialogScreenOnSubmit
            hasDismiss={!!(content.onDismiss || onDismiss)}
            color="primary"
            caption={t(
              content.actionButton || actionButton || 'general.actions.ok'
            )}
            onClick={content.onSubmit || onSubmit}
            disabled={content.actionButtonDisabled || actionButtonDisabled}
            disableUnderline={true}
          />
        </DialogScreenActions>
      </Content>
    </Container>
  )
}

export default DialogScreen

const Container = styled.div`
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: ${({ theme }) => hsla(theme.colors.text, 0.85)};
  z-index: 1100;
  padding: 0 24px;
`
const Content = styled.div<{ wideVersion: boolean }>`
  position: relative;
  margin: 0 auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-height: 90vh;
  max-width: ${({ wideVersion }) => (wideVersion ? 864 : 560)}px;
  min-width: 272px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.lightest};
  padding: ${({ wideVersion }) =>
    wideVersion ? '24px 24px 0px 24px' : '24px 48px 0px 48px'};
  box-shadow: ${shadows.depthFive};

  ${mqTo.S`
    padding: 24px 16px 0px 16px;
  `}
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0;
`

const TransparentContent = styled.div<{ wideVersion: boolean }>`
  position: relative;
  margin: 0 auto;
  right: 0;
  top: 60px;
  max-width: ${({ wideVersion }) =>
    wideVersion ? `calc(1184px / 12 * 10);` : `calc(${sizes.M}px + 40px)`};
  padding: 0 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 120px);

  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InnerContent = styled.div<{ withOverflow?: boolean }>`
  margin-bottom: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.textLight};
  padding-left: 25px; /* These 4 props are made to allow box-shadows within the content to be visible outside of the container */
  padding-right: 25px;
  margin-left: -25px;
  margin-right: -25px;

  ${({ withOverflow }) =>
    withOverflow &&
    `
    overflow-y: scroll;
    max-height: calc(90vh - 166px);

    /* Hide scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `}
`

const DialogScreenActions = styled.div<{ wideVersion: boolean }>`
  display: flex;
  margin: ${({ wideVersion }) => (wideVersion ? '0 -24px' : '0 -48px')};
  border-top: 2px solid ${({ theme }) => theme.colors.textLighter};

  ${mqTo.S`
    margin: 0 -16px;
  `}
`

const DialogScreenOnDismiss = styled(Link)`
  width: 50%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  border-right: 2px solid ${({ theme }) => theme.colors.textLighter};
  text-align: center;
`

const DialogScreenOnSubmit = styled(Link)<
  Pick<{ hasDismiss: boolean }, 'hasDismiss'>
>`
  width: ${(props) => (props.hasDismiss ? '50%' : '100%')};
  display: flex;
  justify-content: center;
  padding: 16px 0;
  text-align: center;
`
