import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconEvaluation = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 68 74"
    fill="none"
  >
    <path
      fill={props.color}
      d="M55.055 50.601v-19.1a.588.588 0 0 0-.588-.589h-11.18a.588.588 0 0 0-.588.588v30.334A23.489 23.489 0 0 0 55.055 50.6ZM40.345 62.613V39.15a.588.588 0 0 0-.588-.589h-11.18a.588.588 0 0 0-.589.589v23.463c1.969.537 4.04.824 6.179.824 2.138 0 4.21-.287 6.178-.824ZM25.635 61.834V47.386a.588.588 0 0 0-.589-.588h-11.18a.588.588 0 0 0-.588.589V50.6a23.489 23.489 0 0 0 12.357 11.233ZM42.108 9.266c.3.236.3.691 0 .927l-10.815 8.473a.588.588 0 0 1-.95-.463V1.256c0-.49.564-.766.95-.463l10.815 8.473Z"
    />
    <path
      fill={props.color}
      d="M34.167 73.866C52.852 73.866 68 58.718 68 40.032 68 24.028 57.423 10.62 42.543 7.101c-.588-.14-.924.584-.453.964l1.455 1.172c.024.02.05.036.075.055.241.186.243.682.004.872-.027.021-.056.04-.082.062l-1.872 1.563c-.375.314-.227.927.247 1.052 12.022 3.168 20.362 14.141 20.362 27.191 0 15.526-12.586 28.112-28.112 28.112-15.526 0-28.112-12.586-28.112-28.112 0-14.228 10.57-25.988 24.287-27.854V6.413C13.457 8.313.333 22.64.333 40.033c0 18.685 15.148 33.833 33.834 33.833Z"
    />
  </svg>
)

export default IconEvaluation
