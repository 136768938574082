export default async (response: Response) => {
  if (!response.ok) {
    const body = await response.json()
    throw Error(body.externalMessage)
  }
  return response
}

export const errorMessages = (error: string) => {
  switch (error) {
    case 'errors.team_already_exists':
      return 'errorHandler.errorTeamEmojiFailed'
    default:
      return 'errorHandler.errorOccured'
  }
}
