import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconSkills = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 26 39"
  >
    <path
      d="M2.182 22.806h9.655L6.745 36.648c-.667 1.76 1.162 2.7 2.307 1.265l15.534-19.414c.291-.359.445-.7.445-1.094 0-.65-.513-1.162-1.23-1.162h-9.656L19.236 2.4c.667-1.76-1.162-2.7-2.307-1.247L1.396 20.55c-.291.376-.445.717-.445 1.093 0 .667.513 1.163 1.23 1.163z"
      fill={props.color}
    />
  </svg>
)

export default IconSkills
