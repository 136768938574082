import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArchive = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 14 12"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.182.182a.727.727 0 0 0-.727.727v.727c0 .402.325.728.727.728h11.636a.727.727 0 0 0 .728-.728V.91a.727.727 0 0 0-.728-.727H1.182Zm.066 3.697a.727.727 0 0 1 .724-.788h10.056c.425 0 .76.364.725.788l-.607 7.272a.727.727 0 0 1-.724.667H2.578a.727.727 0 0 1-.724-.667L1.247 3.88Zm3.025.666c0-.2.163-.363.363-.363h4.546a.364.364 0 0 1 0 .727H4.636a.364.364 0 0 1-.363-.364Z"
      fill={props.color}
    />
  </svg>
)

export default IconArchive
