import Cookies from 'universal-cookie'

export default (authToken: string, refreshToken: string) => {
  const cookies = new Cookies()

  cookies.set('auth_token', authToken, {
    path: '/',
    maxAge: 3600,
  })
  cookies.set('auth_token_expiration', Date.now() + 3600 * 1000, {
    path: '/',
    maxAge: 3600,
  })
  cookies.set('refresh_token', refreshToken, {
    path: '/',
    maxAge: 86400 * 7,
  })
}
