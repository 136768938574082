import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  textColor?: string
  className?: string
}

const IconInsights: React.FC<IProps> = (props) => (
  <svg
    className={props.className}
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 27 29"
    fill="none"
  >
    <path
      fill={props.color}
      d="M21.835 19.783v-7.567a.234.234 0 0 0-.235-.233h-4.46a.234.234 0 0 0-.236.233v12.017a9.349 9.349 0 0 0 4.93-4.45ZM15.965 24.542v-9.296a.234.234 0 0 0-.235-.233h-4.46a.234.234 0 0 0-.235.233v9.296a9.414 9.414 0 0 0 2.465.326c.853 0 1.68-.113 2.465-.326ZM10.096 24.233V18.51a.234.234 0 0 0-.235-.233H5.4a.234.234 0 0 0-.235.233v1.273a9.348 9.348 0 0 0 4.93 4.45ZM16.669 3.407c.12.093.12.274 0 .367l-4.316 3.357a.235.235 0 0 1-.38-.184V.234c0-.195.226-.304.38-.184l4.316 3.357Z"
    />
    <path
      fill={props.color}
      d="M13.5 29C20.956 29 27 22.999 27 15.596c0-6.34-4.22-11.653-10.158-13.047a.215.215 0 0 0-.18.382l.58.465.03.021c.096.074.097.27.002.346-.011.008-.023.015-.033.024l-.747.62c-.15.124-.09.367.099.416 4.796 1.255 8.124 5.603 8.124 10.773 0 6.151-5.022 11.137-11.217 11.137S2.283 21.747 2.283 15.596C2.283 9.959 6.5 5.3 11.973 4.56V2.277C5.238 3.029 0 8.705 0 15.596 0 22.999 6.044 29 13.5 29Z"
    />
  </svg>
)

export default IconInsights
