import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  direction?: 'up' | 'down'
  variant?: boolean
}

const IconThumb = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
      transform: `rotate(${props.direction === 'down' ? 180 : 0}deg)`,
    }}
    viewBox={props.variant ? '0 0 23 19' : '0 0 18 20'}
    fill="none"
  >
    {props.variant ? (
      <>
        <path
          fill={props.color}
          d="M5.936 17.075H5.87c-.483 0-1.248.001-1.902-.19-.36-.106-.796-.298-1.147-.676-.379-.407-.563-.925-.563-1.486 0-.076.004-.15.011-.222-.472-.305-.944-.826-.944-1.692 0-.314.06-.59.158-.832a2.06 2.06 0 0 1-.55-1.425c0-.68.272-1.168.611-1.513a2.007 2.007 0 0 1-.12-.696c0-1.623 1.468-2.303 2.432-2.395.546-.052 1.249-.078 1.84-.091-.144-.545-.29-1.268-.246-2.048.035-.635.186-1.362.494-1.973.285-.564.908-1.39 2.003-1.39 1.231 0 1.959 1.053 1.959 1.958v1.03c0 .262.112.638.406 1.123.285.47.684.951 1.115 1.396l.5 2.11c-1.01-.823-3.244-2.843-3.244-4.628v-1.03c0-.344-.289-.737-.736-.737-.785 0-1.219 1.18-1.276 2.209-.045.815.17 1.557.334 2.12.191.657.312 1.07-.138 1.07-.196 0-1.865 0-2.896.098-.689.066-1.325.486-1.325 1.178v.02c.018.77 1.08 1.01 1.08 1.01s-1.026.065-1.42.66a.92.92 0 0 0-.15.519c0 .166.037.306.1.423.346.642 1.47.607 1.47.607s-1.029.257-1.163 1.052c-.01.056-.015.114-.015.175 0 .226.086.4.218.534.442.448 1.402.448 1.402.448s-.687.262-.687.932c0 .083.007.16.021.23.181.9 1.455.9 2.433.9 1.13 0 2.08-.08 2.874-.205v1.236c-.82.119-1.772.19-2.874.19Z"
        />
        <path
          fill={props.color}
          fillRule="evenodd"
          d="M22.62 16.379c0 .675-.547 1.222-1.222 1.222h-2.454c-.252 0-.485-.075-.68-.206a7.207 7.207 0 0 1-.97.464c-1.158.458-2.934.871-5.565.871h-.065c-.483 0-1.248.001-1.901-.19-.36-.106-.797-.298-1.148-.676-.379-.407-.563-.925-.563-1.485 0-.077.004-.15.011-.223-.472-.304-.943-.826-.943-1.691 0-.315.06-.591.157-.832a2.06 2.06 0 0 1-.55-1.426c0-.679.272-1.168.612-1.513a2.007 2.007 0 0 1-.121-.696c0-1.623 1.468-2.303 2.432-2.395l.116 1.217c-.69.066-1.326.486-1.326 1.178v.02c.018.77 1.08 1.01 1.08 1.01s-1.026.065-1.42.66a.92.92 0 0 0-.15.519c0 .166.037.306.1.423.346.643 1.47.608 1.47.608s-1.029.257-1.163 1.052c-.01.055-.015.114-.015.175 0 .226.086.4.218.534.442.447 1.402.447 1.402.447s-.687.262-.687.933c0 .082.007.159.022.23.18.899 1.454.899 2.432.899 3.454 0 5.225-.748 5.992-1.211.345-.209.487-.36.487-.36v-5.84s-.191-.139-.487-.38c-1.01-.822-3.244-2.842-3.244-4.627V4.06c0-.344-.288-.737-.736-.737-.785 0-1.219 1.18-1.276 2.209-.045.815.17 1.557.335 2.12.19.657.31 1.07-.139 1.07-.196 0-1.865 0-2.895.098L9.65 7.603c.546-.052 1.249-.078 1.84-.09-.144-.546-.289-1.268-.246-2.049.036-.635.186-1.362.495-1.973.284-.564.907-1.39 2.002-1.39 1.231 0 1.959 1.053 1.959 1.958V5.09c0 .262.113.638.406 1.122.285.471.685.951 1.115 1.396.37.382.744.719 1.05.977.198-.13.432-.202.673-.202h2.454c.675 0 1.223.547 1.223 1.223v6.773Zm-3.676-6.773v6.773h2.454V9.606h-2.454Z"
          clipRule="evenodd"
        />
      </>
    ) : (
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M17.727 16.65c0 .753-.61 1.363-1.363 1.363h-2.738c-.28 0-.54-.084-.757-.23a8.055 8.055 0 0 1-1.082.518c-1.293.511-3.273.972-6.208.972h-.072c-.539 0-1.392 0-2.121-.213-.402-.117-.889-.332-1.28-.754-.423-.453-.628-1.032-.628-1.656 0-.086.004-.168.013-.248-.527-.34-1.053-.922-1.053-1.887 0-.35.067-.66.175-.928A2.297 2.297 0 0 1 0 11.997c0-.758.304-1.303.682-1.688a2.238 2.238 0 0 1-.135-.776c0-1.81 1.638-2.569 2.713-2.671l.13 1.357c-.77.073-1.479.542-1.479 1.314a.99.99 0 0 0 0 .022c.02.859 1.204 1.128 1.204 1.128s-1.144.071-1.583.735a1.026 1.026 0 0 0-.168.579c0 .185.041.34.112.471.386.717 1.64.678 1.64.678s-1.148.287-1.298 1.174c-.01.062-.016.127-.016.195 0 .252.095.446.243.595.493.5 1.563.5 1.563.5s-.766.292-.766 1.04c0 .092.008.177.024.256.202 1.003 1.622 1.003 2.713 1.003 3.852 0 5.829-.834 6.684-1.35.384-.233.542-.402.542-.402V9.643s-.213-.155-.542-.423c-1.127-.917-3.618-3.17-3.618-5.161v-1.15c0-.383-.322-.821-.822-.821-.875 0-1.36 1.315-1.423 2.463-.05.91.19 1.737.373 2.365.213.733.347 1.194-.154 1.194-.219 0-2.08 0-3.23.11l-.13-1.358c.61-.058 1.394-.087 2.053-.102-.16-.607-.322-1.414-.273-2.284.039-.709.207-1.52.55-2.201.318-.629 1.013-1.55 2.234-1.55 1.374 0 2.185 1.174 2.185 2.184v1.15c0 .291.126.71.453 1.251.318.526.764 1.061 1.244 1.557.413.427.83.801 1.17 1.09.222-.146.482-.226.751-.226h2.738c.753 0 1.363.61 1.363 1.364v7.555Zm-4.1-7.555v7.555h2.737V9.095h-2.738Z"
        clipRule="evenodd"
      />
    )}
  </svg>
)

export default IconThumb
