import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

// Styles
import { applyTextStyle } from '../styles/typography'

// Components
import { Icon, IconWrapper } from './icon'

// Types
import { IconType } from './icon/IconType'
import { AnyColor } from '../common/models/colors'

interface IProps {
  className?: string
  disabled?: boolean
  caption: string
  color?: AnyColor
  hoverColor?: AnyColor
  icon?: IconType
  iconSize?: number
  disableUnderline?: boolean
  onClick?: (event?: React.MouseEvent) => void
}

const Link = (props: IProps) => {
  const themeContext = useContext(ThemeContext)
  const color = props.color || 'secondary'
  const iconSize = props.iconSize || 13
  return (
    <StyledLink
      className={props.className}
      disableUnderline={props.disableUnderline}
      onClick={props.onClick}
      disabled={props.disabled}
      hoverColor={props.hoverColor || `${props.color}Dark`}
      color={color}
    >
      {props.icon !== undefined && (
        <Icon
          size={iconSize}
          color={themeContext.colors[color]}
          type={props.icon}
        />
      )}
      <LinkLabel>{props.caption}</LinkLabel>
    </StyledLink>
  )
}

const StyledLink = styled.a<{
  color: string
  hoverColor: string
  disabled?: boolean
  disableUnderline?: boolean
  onClick?: () => void
}>`
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.colors[props.color]};
  transition: color 0.3s ease;
  ${applyTextStyle('labelHeavy')}
  text-decoration: ${(props) =>
    props.disableUnderline ? 'none' : 'underline'};
  cursor: ${({ onClick }) => (onClick === undefined ? 'default' : 'pointer')};

  &:hover {
    color: ${(props) =>
      props.onClick === undefined
        ? props.theme.colors[props.color]
        : props.theme.colors[props.hoverColor]};

    ${IconWrapper} path {
      fill: ${(props) =>
        props.onClick === undefined
          ? props.theme.colors[props.color]
          : props.theme.colors[props.hoverColor]};
    }
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.light};
    cursor: default;
    pointer-events: none;
  }

  ${IconWrapper} {
    margin-right: 8px;
  }
`

const LinkLabel = styled.span`
  position: relative;
`

export default Link
