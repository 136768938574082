import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowUp = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 14 14"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.598 7.527a1.322 1.322 0 001.84 0 1.25 1.25 0 000-1.798L8.3.71a1.87 1.87 0 00-2.601 0L.563 5.729a1.25 1.25 0 000 1.798 1.322 1.322 0 001.839 0l2.941-2.875c.145-.141.393-.041.393.159v7.771c0 .683.566 1.236 1.264 1.236.698 0 1.265-.553 1.265-1.236V4.811c0-.2.247-.3.392-.159l2.941 2.875z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowUp
