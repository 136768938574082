import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconMail = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 14 11"
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="m2.55 2 4.27 4.3c.11.112.29.112.402 0l4.27-4.3H2.55Zm9.706.778L9.147 5.91l3.109 3.131V2.778Zm-.765 7.04L8.38 6.683l-.383.386a1.374 1.374 0 0 1-1.95 0l-.404-.406-3.133 3.155h8.982ZM1.744 9.04V2.737L4.873 5.89 1.744 9.04ZM.654 1.636c0-.323.386-.727.71-.727h11.272c.302 0 .71.426.71.727v8.546c0 .301-.408.727-.71.727H1.364c-.302 0-.71-.426-.71-.727V1.636Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconMail
