// Global stylesheet
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import { baseColors, createColor } from './colors'
import { applyTextStyle } from './typography'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @import url('https://fonts.googleapis.com/css?family=Montserrat:600,600italic,500,400,400italic');
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');

  html,
  body {
    width: 100%;
    height: 100%;
    color: black;
    font-family: 'Montserrat', sans-serif;
    ${applyTextStyle('body')}
  }

  * {
    box-sizing: border-box;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  a {
    color: ${createColor(baseColors.primary)};
    text-decoration: none;
  }


`

export default GlobalStyle
