import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPencil = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 12"
  >
    <path
      d="M0.272461 9.45986C0.272461 9.41766 0.289222 9.3772 0.319058 9.34736L7.1539 2.51252L9.48712 4.84574L2.65228 11.6806C2.62244 11.7104 2.58197 11.7272 2.53978 11.7272H0.908825C0.557371 11.7272 0.272461 11.4423 0.272461 11.0908V9.45986ZM11.2779 2.15676C11.5261 2.40493 11.5265 2.80717 11.2788 3.05582L10.1529 4.18622L7.81964 1.853L8.94381 0.724384C9.19218 0.475032 9.59579 0.474634 9.84465 0.723496L11.2779 2.15676Z"
      fill={props.color}
    />
  </svg>
)

export default IconPencil
