import {
  IPdpAggregateStatistics,
  IPdpStatistics,
  PdpStatus,
} from '../../../common/models/Pdp'
import { GET_PDPS } from '../../../constants/api'
import errorHandler from '../errorHandler'

// Request  all submitted pdps (if user is admin) and process them
export default (token: string) =>
  fetch(GET_PDPS(), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(
      async (
        pdpsResponse: Response
      ): Promise<{ pdpStatistics: IPdpStatistics }> => {
        const pdps: IPdpAggregateStatistics[] = await pdpsResponse.json()
        const publishedPdps = pdps.filter(
          (pdp) => pdp.status === PdpStatus.PUBLISHED
        )
        const draftPdps = pdps.filter(
          (pdp) => pdp.status === PdpStatus.DRAFTING
        )

        const pdpStatistics = {
          all: pdps,
          publishedPdps,
          draftPdps,
        }

        return { pdpStatistics }
      }
    )
