import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowDown = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 10 10"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.782 4.632a.925.925 0 0 0-1.287 0 .876.876 0 0 0 0 1.258L4.09 9.404a1.309 1.309 0 0 0 1.821 0L9.507 5.89a.876.876 0 0 0 0-1.258.925.925 0 0 0-1.288 0L6.161 6.644c-.102.099-.275.029-.275-.111v-5.44A.875.875 0 0 0 5 .228a.875.875 0 0 0-.885.865v5.44c0 .14-.174.21-.275.11L1.782 4.633Z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowDown
