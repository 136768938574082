import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconMedal = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 22 28"
    fill="none"
  >
    <path
      fill={props.color}
      d="M14.174.5c-.499 0-.954.283-1.175.731l-2.038 4.142-1.177-2.496-.707-1.534A1.309 1.309 0 0 0 7.888.582H2.083C1.123.582.49 1.58.9 2.449l2.835 6.01a12.054 12.054 0 0 1 7.335-2.473c2.669 0 5.136.863 7.138 2.327l2.803-5.946A1.309 1.309 0 0 0 19.826.5h-5.652Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M20.885 18.093c0 5.421-4.395 9.816-9.816 9.816-5.422 0-9.817-4.395-9.817-9.816 0-5.422 4.395-9.817 9.817-9.817 5.421 0 9.816 4.395 9.816 9.817Zm-9.496-6.577a.41.41 0 0 0-.778 0l-1.356 4.173a.409.409 0 0 1-.39.283H4.479a.41.41 0 0 0-.24.74l3.55 2.579a.41.41 0 0 1 .148.457L6.58 23.922a.41.41 0 0 0 .63.457l3.55-2.58a.409.409 0 0 1 .48 0l3.55 2.58a.41.41 0 0 0 .63-.457l-1.356-4.174a.41.41 0 0 1 .149-.457l3.55-2.58a.41.41 0 0 0-.24-.74h-4.389a.41.41 0 0 1-.389-.282l-1.356-4.173Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconMedal
