import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconGoogle = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 25 25"
    fill="none"
  >
    <g clipPath="url(#microsoft)">
      <path d="M11.9.84H.5v11.4h11.4V.84Z" fill="#F25022" />
      <path d="M24.5.84H13.1v11.4h11.4V.84Z" fill="#7FBA00" />
      <path d="M11.9 13.44H.5v11.4h11.4v-11.4Z" fill="#00A4EF" />
      <path d="M24.5 13.44H13.1v11.4h11.4v-11.4Z" fill="#FFB900" />
    </g>
    <defs>
      <clipPath id="microsoft">
        <path fill="#fff" transform="translate(.5 .84)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default IconGoogle
