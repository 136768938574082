import React from 'react'
import styled from 'styled-components'
import {
  IconChevronUp,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconArrowRight,
  IconArrowTopRight,
  IconDash,
  IconPlus,
  IconClose,
  IconCheck,
  IconLogoSUMM,
  IconSearch,
  IconDropdown,
  IconBurger,
  IconNotification,
  IconLogoSummText,
  IconSwitch,
  IconPencil,
  IconTrashCan,
  IconImage,
  IconPin,
  IconPinFilled,
  IconShow,
  IconHide,
  IconArrowUp,
  IconArrowDown,
  IconCulture,
  IconPeople,
  IconJournal,
  IconManage,
  IconLongArrow,
  IconSkills,
  IconGoals,
  IconWarning,
  IconUser,
  IconTeam,
  IconCompany,
  IconRequest,
  IconLogout,
  IconExternPeople,
  IconNext,
  IconSwitchCompanies,
  IconMicrosoft,
  IconGoogle,
  IconSupport,
  IconDashboard,
  IconLead,
  IconAdmin,
  IconLock,
  IconInfo,
  IconSettings,
  IconAttention,
  IconArchive,
  IconDuplicate,
  IconActivity,
  IconBuddy,
  IconMail,
  IconTextField,
  IconTitle,
  IconLink,
  IconLibrary,
  IconFile,
  IconCopy,
  IconExternalLink,
  IconThumb,
  IconInsights,
  IconEvaluation,
  IconOnboarding,
  IconFeedback,
  IconReviews,
  IconList,
  IconCard,
  IconMedal,
  IconNoUser,
  IconLight,
  IconCheckIn,
} from '.'
import { IconType } from './IconType'

// Styles
import { fastTransition } from '../../styles/effects'

interface IProps {
  type: IconType
  size?: number
  color?: string
  secondaryColor?: string
  thirdColor?: string
  direction?: 'up' | 'down'
  variant?: boolean
  className?: string
  onClick?: () => void
}

interface IconProps extends IProps {
  color: string
  size: number
}

const Icon = (props: IProps) => {
  const size = props.size !== undefined ? props.size : 13
  const color = props.color ? props.color : 'black'
  return (
    <IconWrapper
      className={props.className}
      onClick={props.onClick}
      size={size}
    >
      {props.type === 'ArrowRight' && (
        <IconArrowRight size={size} color={color} />
      )}
      {props.type === 'ArrowUp' && <IconArrowUp size={size} color={color} />}
      {props.type === 'Activity' && <IconActivity size={size} color={color} />}
      {props.type === 'Archive' && <IconArchive size={size} color={color} />}
      {props.type === 'ArrowDown' && (
        <IconArrowDown size={size} color={color} />
      )}
      {props.type === 'ArrowTopRight' && (
        <IconArrowTopRight size={size} color={color} />
      )}
      {props.type === 'Buddy' && <IconBuddy size={size} color={color} />}
      {props.type === 'CheckIn' && <IconCheckIn size={size} color={color} />}
      {props.type === 'Switch' && <IconSwitch size={size} color={color} />}
      {props.type === 'ChevronUp' && (
        <IconChevronUp size={size} color={color} />
      )}
      {props.type === 'ChevronDown' && (
        <IconChevronDown size={size} color={color} />
      )}
      {props.type === 'ChevronLeft' && (
        <IconChevronLeft size={size} color={color} />
      )}
      {props.type === 'ChevronRight' && (
        <IconChevronRight size={size} color={color} />
      )}
      {props.type === 'Dash' && <IconDash size={size} color={color} />}
      {props.type === 'Light' && <IconLight size={size} color={color} />}
      {props.type === 'LongArrow' && (
        <IconLongArrow size={size} color={color} />
      )}
      {props.type === 'Evaluation' && (
        <IconEvaluation size={size} color={color} />
      )}
      {props.type === 'Duplicate' && (
        <IconDuplicate size={size} color={color} />
      )}
      {props.type === 'NoUser' && <IconNoUser size={size} color={color} />}
      {props.type === 'Plus' && <IconPlus size={size} color={color} />}
      {props.type === 'Close' && <IconClose size={size} color={color} />}
      {props.type === 'Search' && <IconSearch size={size} color={color} />}
      {props.type === 'Dropdown' && <IconDropdown size={size} color={color} />}
      {props.type === 'Check' && <IconCheck size={size} color={color} />}
      {props.type === 'LogoSUMM' && (
        <IconLogoSUMM
          size={size}
          color={color}
          secondColor={props.secondaryColor}
          thirdColor={props.thirdColor}
        />
      )}
      {props.type === 'Burger' && <IconBurger size={size} color={color} />}
      {props.type === 'Notification' && (
        <IconNotification size={size} color={color} />
      )}
      {props.type === 'LogoSummText' && (
        <IconLogoSummText size={size} color={color} />
      )}
      {props.type === 'Pencil' && <IconPencil size={size} color={color} />}
      {props.type === 'Image' && <IconImage size={size} color={color} />}
      {props.type === 'Insights' && <IconInsights size={size} color={color} />}
      {props.type === 'TrashCan' && <IconTrashCan size={size} color={color} />}
      {props.type === 'IconPin' && <IconPin size={size} color={color} />}
      {props.type === 'IconPinFilled' && (
        <IconPinFilled size={size} color={color} />
      )}
      {props.type === 'Attention' && (
        <IconAttention size={size} color={color} />
      )}
      {props.type === 'Show' && <IconShow size={size} color={color} />}
      {props.type === 'Hide' && <IconHide size={size} color={color} />}
      {props.type === 'Culture' && <IconCulture size={size} color={color} />}
      {props.type === 'Onboarding' && (
        <IconOnboarding size={size} color={color} />
      )}
      {props.type === 'Feedback' && <IconFeedback size={size} color={color} />}
      {props.type === 'Reviews' && <IconReviews size={size} color={color} />}
      {props.type === 'People' && <IconPeople size={size} color={color} />}
      {props.type === 'Manage' && <IconManage size={size} color={color} />}
      {props.type === 'Mail' && <IconMail size={size} color={color} />}
      {props.type === 'Skills' && <IconSkills size={size} color={color} />}
      {props.type === 'Goals' && <IconGoals size={size} color={color} />}
      {props.type === 'Warning' && <IconWarning size={size} color={color} />}
      {props.type === 'User' && <IconUser size={size} color={color} />}
      {props.type === 'Team' && (
        <IconTeam
          size={size}
          color={color}
          secondaryColor={props.secondaryColor}
        />
      )}
      {props.type === 'Company' && (
        <IconCompany
          size={size}
          color={color}
          secondaryColor={props.secondaryColor}
        />
      )}
      {props.type === 'Journal' && <IconJournal size={size} color={color} />}
      {props.type === 'Request' && <IconRequest size={size} color={color} />}
      {props.type === 'Logout' && (
        <IconLogout
          size={size}
          color={color}
          secondaryColor={props.secondaryColor}
        />
      )}
      {props.type === 'Support' && (
        <IconSupport
          size={size}
          color={color}
          secondaryColor={props.secondaryColor}
        />
      )}
      {props.type === 'SwitchCompanies' && (
        <IconSwitchCompanies size={size} color={color} />
      )}
      {props.type === 'Next' && <IconNext size={size} color={color} />}
      {props.type === 'Google' && <IconGoogle size={size} />}
      {props.type === 'Microsoft' && <IconMicrosoft size={size} />}
      {props.type === 'Dashboard' && (
        <IconDashboard size={size} color={color} />
      )}
      {props.type === 'Thumb' && (
        <IconThumb
          size={size}
          color={color}
          direction={props.direction}
          variant={props.variant}
        />
      )}
      {props.type === 'Lock' && (
        <IconLock size={size} color={color} openLock={props.variant} />
      )}
      {props.type === 'Lead' && <IconLead size={size} color={color} />}
      {props.type === 'Library' && <IconLibrary size={size} color={color} />}
      {props.type === 'File' && <IconFile size={size} color={color} />}
      {props.type === 'Admin' && <IconAdmin size={size} color={color} />}
      {props.type === 'Settings' && <IconSettings size={size} color={color} />}
      {props.type === 'Copy' && <IconCopy size={size} color={color} />}
      {props.type === 'ExternalLink' && (
        <IconExternalLink size={size} color={color} />
      )}
      {props.type === 'Info' && (
        <IconInfo size={size} color={color} textColor={props.secondaryColor} />
      )}
      {props.type === 'ExternPeople' && (
        <IconExternPeople size={size} color={color} />
      )}
      {props.type === 'TextField' && (
        <IconTextField size={size} color={color} />
      )}
      {props.type === 'Title' && <IconTitle size={size} color={color} />}
      {props.type === 'Link' && <IconLink size={size} color={color} />}
      {props.type === 'List' && <IconList size={size} color={color} />}
      {props.type === 'Card' && <IconCard size={size} color={color} />}
      {props.type === 'Medal' && <IconMedal size={size} color={color} />}
    </IconWrapper>
  )
}

export const IconWrapper = styled.span<Pick<IconProps, 'size'>>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin-right: 8px;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;

    path {
      transition: fill ${fastTransition};
    }
  }
`

export default Icon
