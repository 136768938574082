import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconRequest = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 32 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.672.859H1.574a.585.585 0 00-.514.865L4.32 7.7c.082.15.125.319.125.49V20.8a2.34 2.34 0 002.34 2.34h21.887a2.34 2.34 0 002.34-2.34V3.2a2.34 2.34 0 00-2.34-2.34zM9.587 6a1.286 1.286 0 100 2.571h16.711a1.286 1.286 0 000-2.571H9.587zM8.3 12c0-.71.576-1.286 1.286-1.286h16.712a1.286 1.286 0 010 2.57H9.586c-.71 0-1.286-.575-1.286-1.285zm1.286 3.427a1.285 1.285 0 100 2.571h11.998a1.285 1.285 0 100-2.57H9.586z"
      fill={props.color}
    />
  </svg>
)

export default IconRequest
