import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconUser = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 30 34"
    fill="none"
  >
    <path
      d="M15 16.55c3.924 0 7.193-3.465 7.193-7.9 0-4.313-3.287-7.707-7.193-7.707-3.888 0-7.193 3.43-7.176 7.724 0 4.418 3.252 7.882 7.176 7.882zM4.06 33.056h21.88c3.022 0 4.047-.972 4.047-2.651 0-4.525-5.832-10.746-14.987-10.746C5.827 19.66.013 25.88.013 30.406c0 1.679 1.025 2.65 4.047 2.65z"
      fill={props.color}
    />
  </svg>
)

export default IconUser
