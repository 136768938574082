import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconNext = (props: IProps) => (
  <svg
    fill="none"
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 16 16"
  >
    <path
      d="m7.636 11.574 3.122-3.122a.727.727 0 0 0 0-1.029L7.636 4.301a1.029 1.029 0 0 1 1.455-1.454l4.062 4.062a1.454 1.454 0 0 1 0 2.057L9.091 13.03a1.029 1.029 0 0 1-1.455-1.455Z"
      fill={props.color}
    />
    <path
      d="m2.665 11.574 3.122-3.122a.727.727 0 0 0 0-1.029L2.665 4.301a1.029 1.029 0 0 1 1.454-1.454l4.062 4.062a1.455 1.455 0 0 1 0 2.057L4.12 13.03a1.029 1.029 0 0 1-1.454-1.455Z"
      fill={props.color}
    />
  </svg>
)

export default IconNext
