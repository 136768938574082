import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconOnboarding = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 68 68"
    fill="none"
  >
    <path
      fill={props.color}
      d="M68 34.167C68 52.852 52.852 68 34.167 68 15.48 68 .333 52.852.333 34.167.333 15.48 15.481.333 34.167.333 52.852.333 68 15.481 68 34.167Zm-61.945 0c0 15.526 12.586 28.112 28.112 28.112 15.526 0 28.112-12.586 28.112-28.112 0-15.526-12.586-28.112-28.112-28.112-15.526 0-28.112 12.586-28.112 28.112Z"
    />
    <path
      fill={props.color}
      d="M26.58 47.737c4.368 7.494 11.99 9.057 18.785 5.173 5.141-2.951 7.232-6.939 7.102-12.44.068-3.472-.825-7.621-1.688-11.283-.422-1.869-.887-4.248-1.353-5.13-.523-1.038-1.58-1.361-2.616-.86-1.178.581-1.51 1.67-1.367 3.603l.568 6.335c.07.638-.057.963-.318 1.112-.308.177-.636.129-.965-.438l-10.23-17.642a2.24 2.24 0 0 0-3.05-.817 2.19 2.19 0 0 0-.807 3.015l6.915 11.907a.785.785 0 0 1-.47 1.158c-.355.1-.77-.03-.955-.349L27.852 16.8a2.217 2.217 0 0 0-3.03-.812c-1.072.63-1.426 1.952-.808 3.015l8.11 13.99a.857.857 0 0 1-.236 1.103.83.83 0 0 1-1.225-.243L23.35 21.258c-.636-1.068-1.959-1.442-3.049-.817a2.225 2.225 0 0 0-.808 3.015l8.226 14.138c.156.268.122.614-.05.872-.33.495-1.064.5-1.363-.015l-5.202-8.958c-.594-1.076-1.96-1.442-3.026-.83a2.213 2.213 0 0 0-.813 3.033l9.316 16.041Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M38.818 12.381a.883.883 0 0 1 1.032-.701c1.744.333 3.244.816 4.56 1.85 1.313 1.032 2.36 2.542 3.34 4.773a.883.883 0 1 1-1.616.71c-.918-2.087-1.815-3.31-2.814-4.094-.995-.782-2.175-1.195-3.801-1.505a.883.883 0 0 1-.701-1.033Zm.213 3.996a.883.883 0 0 1 1.032-.701c.967.184 1.785.549 2.454 1.15.664.596 1.123 1.374 1.46 2.293a.883.883 0 1 1-1.657.609c-.27-.739-.595-1.24-.983-1.59-.383-.344-.887-.591-1.605-.728a.883.883 0 0 1-.701-1.033ZM19.922 49.045a.883.883 0 0 1-1.187.386c-1.582-.805-2.888-1.687-3.864-3.047-.975-1.356-1.559-3.098-1.88-5.514a.883.883 0 0 1 1.75-.232c.3 2.26.822 3.685 1.563 4.716.738 1.028 1.757 1.753 3.232 2.504a.883.883 0 0 1 .386 1.187Zm.908-3.897a.883.883 0 0 1-1.187.386c-.877-.447-1.561-1.024-2.037-1.788-.47-.758-.695-1.633-.764-2.61a.883.883 0 1 1 1.762-.122c.054.784.226 1.356.501 1.8.273.437.687.815 1.339 1.147a.882.882 0 0 1 .386 1.187Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconOnboarding
