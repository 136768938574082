import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconCulture = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 40 40"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2 1.8A1.8 1.8 0 0120 0 20 20 0 110 20a1.8 1.8 0 013.6 0A16.4 16.4 0 1020 3.6a1.8 1.8 0 01-1.8-1.8z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.232 8.603a1.8 1.8 0 011.8-1.8 13.228 13.228 0 11-12.58 17.32 1.8 1.8 0 013.424-1.113 9.628 9.628 0 109.156-12.607 1.8 1.8 0 01-1.8-1.8z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.231 15.406a1.8 1.8 0 011.8-1.8 6.425 6.425 0 11-4.543 10.969 1.8 1.8 0 012.546-2.546 2.825 2.825 0 101.997-4.823 1.8 1.8 0 01-1.8-1.8z"
      fill={props.color}
    />
  </svg>
)

export default IconCulture
