import { IRequest } from '../../common/models/Request'
import { GET_REQUESTS } from '../../constants/api'
import errorHandler from './errorHandler'

export default (memberId: string, token: string) =>
  fetch(GET_REQUESTS(memberId), {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(async (userRequests: Response) => {
      const requests: IRequest[] = await userRequests.json()

      return { requests: requests || [] }
    })
