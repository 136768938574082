import React from 'react'
import styled from 'styled-components'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  className?: string
  secondColor?: string
  thirdColor?: string
}

const IconLogoSUMM = (props: IProps) => (
  <SVG
    className={props.className}
    size={props.size}
    viewBox="0 0 152 151"
    fill="none"
  >
    <path
      fill={props.secondColor || props.color}
      d="M71.576 79.642h-45.81L9.475 95.851c-12.635 12.557-12.635 32.91 0 45.467 12.635 12.558 33.12 12.558 45.75 0l16.35-16.248-.004-45.428h.004ZM119.205 11.292c5.679 0 11.019 2.197 15.033 6.19 8.291 8.24 8.291 21.645 0 29.88l-12.796 12.717H91.225v-29.73l12.947-12.867c4.014-3.989 9.354-6.19 15.033-6.19Zm0-11.021c-8.277 0-16.56 3.137-22.877 9.415L80.13 25.784v45.317h45.906l16.042-15.943c12.635-12.557 12.635-32.915 0-45.472C135.76 3.408 127.483.271 119.201.271h.004Z"
    />
    <path
      fill={props.thirdColor || props.color}
      d="M142.082 141.394c12.636-12.557 12.636-32.915 0-45.472l-16.349-16.244h-1.567l-44.036-.036v45.654l16.198 16.098c12.635 12.557 33.119 12.557 45.75 0h.004Z"
    />
    <path
      fill={props.color}
      d="M71.581 71.176V25.794L55.231 9.606c-12.635-12.557-33.12-12.557-45.75 0-12.635 12.557-12.635 32.915 0 45.467L25.68 71.171h45.907l-.005.005Z"
    />
  </SVG>
)

export default IconLogoSUMM

const SVG = styled.svg<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`
