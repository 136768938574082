import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPin = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 9 12"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.97519 0.272705H1.18125C0.862582 0.272705 0.601855 0.533432 0.601855 0.852099C0.601855 1.17077 0.862582 1.43149 1.18125 1.43149H1.76064V4.32846C1.76064 5.09087 1.27279 5.73677 0.591452 5.97181C0.288955 6.07616 0.0224609 6.32605 0.0224609 6.64604C0.0224609 6.96603 0.281864 7.22543 0.601854 7.22543H3.48144V11.2145C3.48144 11.2572 3.4984 11.2981 3.52858 11.3283L3.83323 11.633C3.95893 11.7587 4.16274 11.7587 4.28844 11.633L4.59309 11.3283C4.62327 11.2981 4.64023 11.2572 4.64023 11.2145V7.22543H7.55458C7.87457 7.22543 8.13397 6.96603 8.13397 6.64604C8.13397 6.32605 7.86748 6.07616 7.56498 5.97181C6.88364 5.73677 6.39579 5.09087 6.39579 4.32846V1.43149H6.97519C7.29385 1.43149 7.55458 1.17077 7.55458 0.852099C7.55458 0.533432 7.29385 0.272705 6.97519 0.272705Z"
      fill={props.color}
    />
  </svg>
)

export default IconPin
