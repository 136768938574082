import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconManage = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 36 30"
  >
    <path
      d="M24.118 8.355c1.777 0 3.281-1.145 3.863-2.717h5.913c.7 0 1.316-.616 1.316-1.385s-.616-1.367-1.316-1.367H27.98c-.565-1.59-2.086-2.751-3.863-2.751-1.777 0-3.315 1.162-3.88 2.751H2.142A1.35 1.35 0 00.773 4.253c0 .77.599 1.385 1.368 1.385h18.115a4.118 4.118 0 003.862 2.717zm0-2.102c-1.128 0-2-.889-2-2.017s.872-2 2-2c1.128 0 2 .872 2 2 0 1.128-.872 2.017-2 2.017zM2.072 13.31c-.7 0-1.299.615-1.299 1.384 0 .77.599 1.367 1.3 1.367H8.19a4.102 4.102 0 003.863 2.752c1.777 0 3.298-1.145 3.88-2.752h17.892c.77 0 1.384-.598 1.384-1.367s-.615-1.384-1.384-1.384h-17.91a4.118 4.118 0 00-3.862-2.717A4.118 4.118 0 008.19 13.31H2.072zm9.98 3.384c-1.11 0-1.999-.889-1.999-2 0-1.128.889-2 2-2 1.128 0 2 .872 2 2 0 1.111-.872 2-2 2zm12.066 12.544a4.11 4.11 0 003.863-2.735h5.913c.7 0 1.316-.598 1.316-1.367s-.616-1.384-1.316-1.384H27.98a4.095 4.095 0 00-3.863-2.717 4.095 4.095 0 00-3.862 2.717H2.14c-.77 0-1.368.615-1.368 1.384a1.35 1.35 0 001.368 1.367h18.098c.58 1.59 2.102 2.735 3.88 2.735zm0-2.102c-1.128 0-2-.889-2-2 0-1.145.872-2 2-2 1.128 0 2 .855 2 2 0 1.111-.872 2-2 2z"
      fill={props.color}
    />
  </svg>
)

export default IconManage
