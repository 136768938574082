import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLead = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 12"
  >
    <path
      d="M5.181 11.631c-.357.149-.815-.138-1.021-.64L1.01 2.97c-.207-.501-.085-1.028.273-1.177.358-.148.815.138 1.022.64l3.15 8.023c.206.501.084 1.028-.274 1.176ZM4.957 1.502c-.76-1.079-1.792-.424-2.352.131a.202.202 0 0 0-.043.22l1.751 4.376a.216.216 0 0 0 .33.088c1.033-.778 1.595-.62 1.821-.344.291.354.51.727 1.196.382 1.538-.771 1.744-1.617 3.175-1.439.164.02.3-.14.242-.294L9.387.166a.167.167 0 0 0-.1-.102c-.164-.057-.606-.152-1.28.116-.53.211-.998.661-1.508.992-.635.411-1.3.675-1.542.33Z"
      fill={props.color}
    />
  </svg>
)

export default IconLead
