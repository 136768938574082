import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  secondaryColor?: string
}

const IconLogout = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 28 22"
    fill="none"
  >
    <path
      d="M0 4.598v12.804c0 2.472 1.34 3.78 3.848 3.78h11.023c2.51 0 3.849-1.308 3.849-3.78v-2.916h-2.6v2.683c0 .953-.5 1.485-1.533 1.485H4.122c-1.022 0-1.521-.532-1.521-1.485V4.83c0-.964.5-1.474 1.52-1.474h10.467c1.034 0 1.533.51 1.533 1.474v2.727h2.6v-2.96c0-2.46-1.34-3.78-3.849-3.78H3.848C1.34.818 0 2.138 0 4.598z"
      fill={props.secondaryColor || props.color}
    />
    <path
      d="M5.866 12.605c-.867 0-1.624-.718-1.624-1.605S5 9.41 5.866 9.41H21.49l2.318.138-1.199-.887-2.19-1.988a1.472 1.472 0 01-.474-1.07c0-.749.584-1.391 1.403-1.391.41 0 .725.153 1.025.443l5.06 5.107c.426.412.568.825.568 1.238 0 .428-.142.825-.567 1.254l-5.061 5.106c-.3.275-.615.428-1.025.428-.82 0-1.403-.642-1.403-1.407 0-.382.174-.78.473-1.04l2.191-2.002 1.199-.871-2.318.137H5.866z"
      fill={props.color}
    />
  </svg>
)

export default IconLogout
