import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconNotification = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 22 26"
  >
    <path
      d="M11.176 25.682a2.584 2.584 0 002.576-2.576H8.6a2.575 2.575 0 002.576 2.576zm7.728-7.728v-6.688c0-3.954-2.112-7.264-5.796-8.14V2.25a1.932 1.932 0 10-3.864 0v.876c-3.696.876-5.796 4.173-5.796 8.14v6.688l-2.31 2.31a.91.91 0 00.644 1.555H20.57a.91.91 0 00.644-1.555l-2.309-2.31z"
      fill={props.color}
    />
  </svg>
)

export default IconNotification
