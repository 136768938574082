import { css, FlattenSimpleInterpolation } from 'styled-components'
import { IScreenRange, IScreenRanges } from '../common/models/responsive'
import { IGridConfig } from '../common/models/grid'

// A breakpoint defines exactly where a responsive trigger happens
export enum Breakpoint {
  XS = 320,
  S = 544,
  M = 768,
  L = 1024,
  XL = 1440,
}

export const gridConfig: IGridConfig = {
  maxColumns: 12,
  breakpoints: [
    // Note: these may not correspond w/ already defined breakpoints
    // eg. the 'S' breakpoint is not needed in the grid
    {
      name: 'xs',
      startsFrom: Breakpoint.XS,
      gutterWidth: 16,
    },
    {
      name: 'm',
      startsFrom: Breakpoint.M,
      gutterWidth: 32,
    },
    {
      name: 'l',
      startsFrom: Breakpoint.L,
      gutterWidth: 48,
    },
    {
      name: 'xl',
      startsFrom: Breakpoint.XL,
      gutterWidth: 64,
    },
  ],
}

// A screen range defines a range throughout which the styles stay the same
export const screenRanges: IScreenRanges = {
  xs: { from: 0, to: Breakpoint.S },
  s: { from: Breakpoint.S, to: Breakpoint.M },
  m: { from: Breakpoint.M, to: Breakpoint.L },
  l: { from: Breakpoint.L, to: Breakpoint.XL },
  xl: { from: Breakpoint.XL },
}

/**
 * Create the needed CSS for a given media query (works like a mixin)
 * @param range The range to use (passed styles will only be applied if screen size corresponds to this range)
 * @param styles The styles to conditionally apply
 */
export const applyResponsive = (
  range: IScreenRange,
  styles: FlattenSimpleInterpolation | string
) => {
  let mediaQuery = '@media screen'
  // Conditionally build the media query declaration, based on the set limits
  if (range.from && range.to) {
    mediaQuery += ` and
      (min-width: ${range.from / 16}em) and
      (max-width: ${range.to / 16}em)`
  } else if (range.from && !range.to) {
    mediaQuery += ` and
      (min-width: ${range.from / 16}em)`
  } else if (!range.from && range.to) {
    mediaQuery += ` and
      (max-width: ${range.to / 16}em)`
  }

  return css`
    ${mediaQuery} {
      ${styles}
    }
  `
}
