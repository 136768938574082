import React from 'react'
import styled, { css } from 'styled-components'
import { IGridBreakpoint } from '../../common/models/grid'
import { gridConfig } from '../../styles/responsive'
import { Col } from './GridCol'

interface IProps {
  // Default tag name is 'div'
  tagName?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  className?: string
  children?: React.ReactNode
  alignType?: 'center' | 'end' | 'reverse'
}

const GridRow: React.FC<IProps> = ({
  tagName,
  alignType,
  children,
  className,
}) => {
  !tagName && (tagName = 'div')

  return (
    <Row alignType={alignType} as={tagName} className={className}>
      {children}
    </Row>
  )
}

const getRowStyles = (bp: IGridBreakpoint) => {
  const size = bp.startsFrom
  const gutterWidth = bp.gutterWidth
  const halfGutterWidth = gutterWidth * 0.5
  const gutterCompensation = halfGutterWidth * -1
  return css`
    @media only screen and (min-width: ${size}px) {
      margin-right: ${gutterCompensation}px;
      margin-left: ${gutterCompensation}px;
      ${Col} {
        padding-right: ${halfGutterWidth}px;
        padding-left: ${halfGutterWidth}px;
      }
    }
  `
}

const Row = styled.div<Pick<IProps, 'alignType'>>`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  ${gridConfig.breakpoints.map((bp) => getRowStyles(bp))}
  ${(props) =>
    props.alignType === 'center' &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.alignType === 'end' &&
    css`
      justify-content: flex-end;
    `}
`

export default GridRow
