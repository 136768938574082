import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconNoUser = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 66 66"
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M35 0c5.277 0 9.99 3.129 12.496 7.847L26.687 28.656c-3.647-2.868-6.05-7.59-6.05-13.023C20.6 6.94 27.217 0 35 0ZM19.925 41.075l-14.1 14.1-3.997 3.997a4 4 0 1 0 5.657 5.656l.562-.562 26.379-26.378L64.828 7.485a4 4 0 0 0-5.656-5.657l-10.16 10.16-18.723 18.723-10.364 10.364ZM13.1 65h-.13l26.82-26.82C55.336 40.126 65 51.301 65 59.633 65 63.032 62.948 65 56.899 65H13.1Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconNoUser
