import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconShow = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 88 88"
    fill="none"
  >
    <path
      d="M44.05 28.667c15.837 0 26.877 10.04 31.68 15.453C71.06 50.243 60.02 62 44.05 62c-14.733 0-26.433-11.787-31.593-18.023 4.976-5.49 16.056-15.31 31.593-15.31zm0-6.667C18.82 22 4 43.837 4 43.837s16.117 24.83 40.05 24.83c25.777 0 39.95-24.83 39.95-24.83S69.697 22 44.05 22zM44 32c-7.363 0-13.333 5.973-13.333 13.333 0 7.364 5.97 13.334 13.333 13.334s13.333-5.97 13.333-13.334C57.333 37.973 51.363 32 44 32z"
      fill={props.color}
    />
  </svg>
)

export default IconShow
