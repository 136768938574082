import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconGoals = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 40 40"
    fill="none"
  >
    <path d="M23 20a3 3 0 11-6 0 3 3 0 016 0z" fill={props.color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 22.507a2.507 2.507 0 100-5.014 2.507 2.507 0 000 5.014zM20 23a3 3 0 100-6 3 3 0 000 6z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.92 5.713c-1.879 0-3.738.369-5.473 1.085A14.297 14.297 0 009.81 9.886a14.23 14.23 0 00-3.097 4.617A14.184 14.184 0 009.81 30.01a14.297 14.297 0 004.637 3.088 14.343 14.343 0 0015.583-3.088 14.23 14.23 0 003.096-4.618A14.183 14.183 0 0030.03 9.886a14.298 14.298 0 00-4.637-3.088 14.344 14.344 0 00-5.473-1.085zM13.35 4.14a17.22 17.22 0 0118.71 3.71 17.107 17.107 0 013.723 5.55 17.06 17.06 0 01-3.722 18.648 17.173 17.173 0 01-5.57 3.709 17.222 17.222 0 01-13.142 0 17.174 17.174 0 01-5.57-3.71 17.107 17.107 0 01-3.722-5.55A17.06 17.06 0 017.779 7.848a17.174 17.174 0 015.57-3.71z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 20.061c0-.715.58-1.294 1.294-1.294H8.74a1.295 1.295 0 010 2.589H1.294C.58 21.356 0 20.776 0 20.061zM29.966 20.061c0-.715.58-1.294 1.294-1.294h7.445a1.295 1.295 0 010 2.589H31.26c-.715 0-1.294-.58-1.294-1.295zM19.931 30c.835 0 1.512.677 1.512 1.512v6.976a1.512 1.512 0 01-3.024 0v-6.976c0-.835.677-1.512 1.512-1.512zM19.931 0c.835 0 1.512.677 1.512 1.512v6.976a1.512 1.512 0 01-3.024 0V1.512C18.419.677 19.096 0 19.93 0z"
      fill={props.color}
    />
  </svg>
)

export default IconGoals
