import {
  ICompanyMember,
  ICompanyHabit,
  ICompanyMemberPure,
  ICompanyLevel,
} from './Company'

export enum FeedbackDirection {
  TOP = 'top',
  TIP = 'tip',
}

export enum FeedbackStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export interface IExternalFeedbacker {
  fullName: string
  email: string
  emailSent: boolean
}
export interface IFeedbackPure {
  _id?: any
  companyId: string
  memberId: string
  senderId?: string
  dateCreated: Date
  dateSubmitted?: Date
  status: FeedbackStatus
  termNumber: number
  externalFeedbackId?: string
  externalFeedbacker?: IExternalFeedbacker
  assessment: IFeedbackAssessmentPure[]
  isFeedbackRequested?: boolean
}

export interface IFeedback extends IFeedbackPure {
  member: ICompanyMember
  sender?: ICompanyMember
}

export type IFeedbackBasics = Pick<
  IFeedbackPure,
  | '_id'
  | 'senderId'
  | 'status'
  | 'memberId'
  | 'externalFeedbackId'
  | 'externalFeedbacker'
  | 'isFeedbackRequested'
>
export type IFeedbackPrivacyBasics = Pick<
  IFeedbackPure,
  '_id' | 'senderId' | 'memberId' | 'status'
>

export interface ICompanyFeedbackPlanning {
  assignedFeedbacksPerMember: Map<string, number>
  memberFeedbacks: Map<string, IFeedbackBasics[]>
  feedbacksOverview: IFeedbackOverview[]
  feedbackState: IFeedbackPhaseStatus
}

export interface IFeedbackPlanningModal {
  assignedFeedbacksPerMember: Map<string, number>
  feedbacksOverview: IFeedbackOverview[]
}

export interface IFeedbackPlanningOfmember {
  assignedFeedbacksPerMember: Map<string, number>
  memberFeedbacks: IFeedbackPrivacyBasics[]
}
export interface IFeedbackOverview {
  memberId: string
  assessments: IFeedbackBasics[]
}

export interface IAssesseeDetailed extends IFeedbackOverview {
  assesseeDetails?: ICompanyMember
  assessments: IAssessmentDetailed[]
}

export interface IAssessmentDetailed extends IFeedbackBasics {
  assessor?: ICompanyMember
}

export interface IFeedbackAssessmentPure {
  _id?: any
  habitId: string
  fields: IFeedbackAssessmentFieldsPure
}

export interface IFeedbackAssessmentFieldsPure {
  alignmentValue?: number
  textFeedback: IFeedbackFormText[]
}

export interface IFeedbackAssessmentFieldsRequest {
  alignmentValue?: number
  textFeedback?: IFeedbackFormText[]
}

// This interface should be used from the frontend to create a request
export interface IFeedbackAssessmentRequest {
  habitId: string
  fields: IFeedbackAssessmentFieldsRequest
}

export interface IFeedbackFormText {
  text: string
  direction: FeedbackDirection
}

export interface IFeedbackMemberResultAggregation {
  memberId: string
  external: boolean
  results: { [key: string]: number }
}

export interface IMemberFeedbackStatisticsPure {
  chartData: IFeedbackMemberResultAggregation[] | undefined
  feedbacks: IFeedbackPure[]
  alignmentsAvailable: boolean
}

export interface IMemberFeedbackStatistics
  extends IMemberFeedbackStatisticsPure {
  feedbacks: IFeedback[]
  chartDataMapInternal: ICompanyFeedbackAggregation | undefined
  chartDataMapExternal?: ICompanyFeedbackAggregation | undefined
}

export interface IFeedbackPhaseStatus {
  planningStarted: boolean
  planningFinalized: boolean
  feedbackCompleted: boolean
}

export interface IFeedbackCompanyStatusResponse {
  termFeedbacks: IFeedbackBasics[]
  feedbackState: IFeedbackPhaseStatus
}

export interface IExternalFeedback {
  member: ICompanyMemberPure
  teamInfoDetails?: {
    level?: ICompanyLevel
    teamName: string
    teamIcon: string
  }
  memberCompanyName: string
  memberCompanyHabits: ICompanyHabit[]
}

export interface IExternalFeedbackRequest {
  fullName: string
  email: string
}

export type ICompanyFeedbackAggregation = Map<string, { [key: string]: number }>
