import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLight = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      fill={props.color}
      d="M9.753 10.927v-.516c0-.907.367-1.779 1.006-2.391.8-.766 1.24-1.8 1.24-2.91 0-2.216-1.793-4.02-4-4.02-.15 0-.303.01-.453.026-1.911.212-3.433 1.831-3.54 3.766a4.034 4.034 0 0 0 1.209 3.113 3.4 3.4 0 0 1 1.032 2.415v.517c0 .548.444.994.99.994h1.526c.546 0 .99-.446.99-.994Zm-.965-.238a.263.263 0 0 1-.262.263H7.474a.263.263 0 0 1-.262-.263v-.279a4.137 4.137 0 0 0-1.253-2.94 3.3 3.3 0 0 1-.989-2.547c.088-1.582 1.094-2.668 2.656-2.841.124-.014.25-.021.374-.021 1.804 0 3.035 1.236 3.035 3.05 0 .908-.36 1.753-1.015 2.38a4.055 4.055 0 0 0-1.232 2.92v.278ZM9.403 12.29H6.707c-.172 0-.311.16-.311.357 0 .197.139.357.31.357h2.697c.172 0 .311-.16.311-.357 0-.198-.139-.358-.31-.358ZM9.714 13.6c0-.198-.139-.358-.31-.358H6.706c-.172 0-.311.16-.311.357 0 .198.139.357.31.357h2.698c.171 0 .31-.16.31-.357ZM9.122 14.552c0-.198-.15-.357-.337-.357h-1.46c-.186 0-.337.16-.337.357 0 .197.151.357.337.357h1.46c.186 0 .337-.16.337-.357ZM15.338 6.242a.388.388 0 0 0 .389-.389.389.389 0 0 0-.39-.389h-2.241a.389.389 0 1 0 0 .778h2.242ZM2.974 6.06a.388.388 0 0 0 .39-.389.389.389 0 0 0-.39-.389H.732a.389.389 0 1 0 0 .778h2.242ZM13.335 10.977a.388.388 0 0 0 .55 0 .389.389 0 0 0 0-.55L12.302 8.84a.389.389 0 1 0-.55.55l1.584 1.586ZM2.664 10.977a.388.388 0 0 1-.55 0 .389.389 0 0 1 0-.55L3.699 8.84a.389.389 0 1 1 .55.55l-1.585 1.586ZM1.555 1.668a.388.388 0 0 1-.143-.531.389.389 0 0 1 .532-.143l1.941 1.121a.389.389 0 1 1-.389.674l-1.941-1.12ZM14.333 1.588a.388.388 0 0 0 .142-.532.389.389 0 0 0-.531-.142l-1.942 1.12a.389.389 0 1 0 .39.675l1.94-1.121Z"
    />
  </svg>
)

export default IconLight
