import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconTrashCan = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 10 12"
  >
    <path
      d="M2.41116 11.7273C2.07729 11.7273 1.8002 11.4692 1.77641 11.1362L1.23096 3.49986C1.20464 3.13149 1.49639 2.81816 1.8657 2.81816H8.13519C8.50449 2.81816 8.79624 3.13149 8.76993 3.49986L8.22448 11.1362C8.20069 11.4692 7.92359 11.7273 7.58973 11.7273H2.41116ZM8.81862 0.909069C9.17008 0.909069 9.45499 1.19398 9.45499 1.54543C9.45499 1.89689 9.17008 2.1818 8.81862 2.1818H1.18226C0.830808 2.1818 0.545898 1.89689 0.545898 1.54543C0.545898 1.19398 0.830808 0.909069 1.18226 0.909069H2.50958C2.67835 0.909069 2.84022 0.842024 2.95956 0.722682L3.22315 0.459092C3.34249 0.33975 3.50435 0.272705 3.67313 0.272705H6.32776C6.49654 0.272705 6.6584 0.33975 6.77774 0.459092L7.04133 0.722682C7.16067 0.842023 7.32253 0.909069 7.49131 0.909069H8.81862Z"
      fill={props.color}
    />
  </svg>
)

export default IconTrashCan
