import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconTitle = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 28 22"
    fill="none"
  >
    <path
      fill={props.color}
      d="M19.306.364c.226 0 .409.183.409.409v20.454a.41.41 0 0 1-.409.41h-4.134a.41.41 0 0 1-.41-.41v-7.903a.41.41 0 0 0-.408-.41H5.45a.41.41 0 0 0-.409.41v7.903a.41.41 0 0 1-.41.41H.5a.41.41 0 0 1-.41-.41V.773A.41.41 0 0 1 .5.363h4.134a.41.41 0 0 1 .409.41v7.57c0 .225.183.408.41.408h8.902a.41.41 0 0 0 .409-.409V.772a.41.41 0 0 1 .409-.408h4.134ZM27.499 8.873a.41.41 0 0 1 .409.409v11.945a.41.41 0 0 1-.41.41h-2.152a.41.41 0 0 1-.41-.41v-9.575a.409.409 0 0 0-.408-.409h-1.75a.41.41 0 0 1-.41-.409V9.282a.41.41 0 0 1 .41-.41h4.72Z"
    />
  </svg>
)

export default IconTitle
