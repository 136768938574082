import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconWarning = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 74 66"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.656 55.17L39.134 7.5c-1.155-2-4.042-2-5.197 0L6.416 55.17c-1.155 2 .289 4.5 2.598 4.5h55.045c2.31 0 3.753-2.5 2.598-4.5zM44.33 4.5c-3.464-6-12.124-6-15.589 0L1.22 52.17c-3.464 6 .866 13.5 7.794 13.5h55.045c6.928 0 11.258-7.5 7.794-13.5L44.33 4.5zm-3.178 47.998a4.833 4.833 0 11-9.667 0 4.833 4.833 0 019.667 0zm-6.854-9.94c.34 2.623 4.136 2.623 4.475 0l3.205-24.761a2.256 2.256 0 00-2.238-2.546h-6.409a2.256 2.256 0 00-2.237 2.546l3.204 24.762z"
      fill={props.color}
    />
  </svg>
)

export default IconWarning
