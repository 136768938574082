import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconAdmin = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 75 50"
  >
    <path
      d="M10.1041 52.4311C10.1041 50.2671 11.8137 48.5127 13.9225 48.5127H57.1973C59.3061 48.5127 61.0156 50.2671 61.0156 52.4311C61.0156 54.5952 59.3061 56.3495 57.1973 56.3495H13.9225C11.8137 56.3495 10.1041 54.5952 10.1041 52.4311Z"
      fill={props.color}
    />
    <path
      d="M56.017 44.9112H14.8494C13.5402 44.9112 12.4054 44.0217 12.1161 42.7688L3.74266 11.4645C3.4533 10.2114 5.39258 9.44809 6.70191 9.45336C7.16502 9.45522 8.79523 9.59733 8.94141 10.0181C11.5689 17.5811 28.892 51.4429 32.5705 11.2213C32.6561 10.2853 37.493 10.2815 37.493 11.2213C37.493 21.3627 42.0293 49.8959 60.7717 10.7056C61.0443 10.1357 62.8941 10.1713 63.4946 10.3881C64.4852 10.7457 66.4417 10.4477 66.2408 11.4645L58.7684 42.6845C58.5129 43.9774 57.3592 44.9112 56.017 44.9112Z"
      fill={props.color}
    />
    <path
      d="M12.1007 6.3902C12.1007 9.63629 9.41904 12.2678 6.11109 12.2678C2.80313 12.2678 0.121502 9.63629 0.121502 6.3902C0.121502 3.14411 2.80313 0.512634 6.11109 0.512634C9.41904 0.512634 12.1007 3.14411 12.1007 6.3902Z"
      fill={props.color}
    />
    <path
      d="M41.0503 6.3902C41.0503 9.63629 38.3687 12.2678 35.0607 12.2678C31.7528 12.2678 29.0712 9.63629 29.0712 6.3902C29.0712 3.14411 31.7528 0.512634 35.0607 0.512634C38.3687 0.512634 41.0503 3.14411 41.0503 6.3902Z"
      fill={props.color}
    />
    <path
      d="M70 6.3902C70 9.63629 67.3184 12.2678 64.0104 12.2678C60.7025 12.2678 58.0208 9.63629 58.0208 6.3902C58.0208 3.14411 60.7025 0.512634 64.0104 0.512634C67.3184 0.512634 70 3.14411 70 6.3902Z"
      fill={props.color}
    />
  </svg>
)

export default IconAdmin
