import {
  ICompanyMemberTeam,
  ICompanyMemberTeamOptionalTeam,
} from '../common/models/Company'

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined
}

export const notEmptyTeam = (
  value: ICompanyMemberTeamOptionalTeam
): value is ICompanyMemberTeam => {
  return value.team !== undefined
}
