import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconPeople = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 42 28"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.059 10.82c-1.07 1.709-2.862 2.81-4.867 2.81-2.136 0-4.03-1.249-5.067-3.147a6.973 6.973 0 01-.835-3.337c-.004-.93.18-1.81.513-2.603.93-2.219 3.021-3.75 5.389-3.75 2.255 0 4.253 1.376 5.243 3.423l.069.147c.377.833.59 1.77.59 2.768a6.932 6.932 0 01-1.035 3.689zM13.565 7.15v-.004h1.725l-1.725.007v-.004zm0 0c0 1.79.538 3.468 1.461 4.835-.913.885-2.11 1.416-3.408 1.416-2.806 0-5.146-2.486-5.146-5.64-.015-3.068 2.355-5.524 5.146-5.524 1.068 0 2.076.357 2.912.973a8.347 8.347 0 00-.965 3.94zM30.19 27.206H12.18c-1.209 0-2.028-.191-2.55-.548a1.772 1.772 0 01-.034-.025c-.52-.372-.73-.917-.73-1.608 0-2.946 2.998-6.768 7.992-8.232a15.385 15.385 0 014.335-.606 15.4 15.4 0 014.086.538l.071.02c5.089 1.425 8.156 5.3 8.156 8.28 0 .706-.22 1.26-.766 1.633-.521.357-1.34.548-2.549.548zm.192-13.806c-1.19 0-2.296-.447-3.175-1.204a8.654 8.654 0 001.612-5.066 8.39 8.39 0 00-1.069-4.114 4.872 4.872 0 012.632-.78c2.791 0 5.16 2.456 5.146 5.524 0 3.154-2.34 5.64-5.146 5.64zm-19.274 4.474c.931-.76 2.01-1.435 3.219-1.98a12.277 12.277 0 00-2.724-.299c-6.44 0-10.728 4.74-10.728 8.62 0 1.542.741 2.443 2.791 2.443h3.796a4.04 4.04 0 01-.323-1.633c0-2.545 1.579-5.201 3.97-7.151zm24.122 7.151a4.04 4.04 0 01-.323 1.633h3.427c2.05 0 2.791-.901 2.791-2.442 0-3.882-4.288-8.62-10.728-8.62-.88 0-1.705.087-2.473.243a14.596 14.596 0 013.339 2.035c2.388 1.95 3.967 4.606 3.967 7.151z"
      fill={props.color}
    />
  </svg>
)

export default IconPeople
