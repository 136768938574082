import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowTopRight = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 48 48"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4959 40.6007C36.5317 43.7253 39.0936 46.2873 42.2182 46.3231C45.3428 46.3588 47.8468 43.8548 47.8111 40.7302L47.4496 9.12805C47.3991 4.7092 43.7759 1.08604 39.3571 1.03549L7.75495 0.674023C4.63035 0.638284 2.12633 3.1423 2.16207 6.2669C2.19781 9.39151 4.75977 11.9535 7.88437 11.9892L25.9786 12.1962C26.8696 12.2064 27.3282 13.2888 26.7054 13.9116L2.52608 38.0909C0.402487 40.2145 0.442322 43.6973 2.61506 45.8701C4.78779 48.0428 8.27065 48.0826 10.3942 45.959L34.5735 21.7798C35.1964 21.1569 36.2787 21.6155 36.2889 22.5065L36.4959 40.6007Z"
      fill={props.color}
    />
  </svg>
)

export default IconArrowTopRight
