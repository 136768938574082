import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconDuplicate = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 10 10"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M1.102.227C.532.227.068.69.068 1.261V6.99c0 .57.463 1.034 1.034 1.034h.955v.795c0 .571.463 1.034 1.034 1.034h5.727c.571 0 1.034-.463 1.034-1.034V3.091c0-.571-.463-1.034-1.034-1.034h-.954V1.26C7.864.691 7.4.227 6.83.227H1.102Zm1.99 1.83h3.976V1.26a.239.239 0 0 0-.238-.238H1.102a.239.239 0 0 0-.238.238V6.99c0 .131.107.238.238.238h.955V3.091c0-.571.463-1.034 1.034-1.034ZM5.874 3.33c.211 0 .382.17.382.381V5.24c0 .14.114.254.255.254h1.527a.382.382 0 1 1 0 .764H6.512a.255.255 0 0 0-.255.254V8.04a.382.382 0 0 1-.764 0V6.51a.255.255 0 0 0-.254-.254H3.712a.382.382 0 0 1 0-.764h1.527c.14 0 .254-.114.254-.254V3.71c0-.21.171-.381.382-.381Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconDuplicate
