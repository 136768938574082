import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  textColor?: string
  className?: string
}

const IconImage: React.FC<IProps> = (props) => (
  <svg
    className={props.className}
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 10"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.864 3.807a1.193 1.193 0 1 0 0-2.387 1.193 1.193 0 0 0 0 2.387Zm0-.478a.716.716 0 1 0 0-1.431.716.716 0 0 0 0 1.431Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.91 1.102c0-.395.32-.716.715-.716h8.91c.395 0 .715.32.715.716v7.796c0 .395-.32.716-.716.716H1.625a.716.716 0 0 1-.716-.716V1.102Zm.795 6.372v1.344h8.75V7.07L8.524 5.072 7.16 6.276 5.185 4.064l-3.48 3.41Zm0-1.114 3.519-3.447 2.003 2.243 1.34-1.183 1.888 1.952V1.182h-8.75V6.36Z"
      fill={props.color}
    />
  </svg>
)

export default IconImage
