import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconDropdown = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 14 9"
  >
    <path
      d="M2.45459 1.36362L6.35722 5.26625C6.71224 5.62127 7.28784 5.62127 7.64286 5.26625L11.5455 1.36362C12.0476 0.861541 12.8616 0.861541 13.3637 1.36362C13.8658 1.8657 13.8658 2.67972 13.3637 3.1818L8.28569 8.25979C7.57564 8.96983 6.42444 8.96983 5.71439 8.25979L0.636403 3.1818C0.134325 2.67972 0.134325 1.8657 0.636402 1.36362C1.13848 0.861541 1.95251 0.861541 2.45459 1.36362Z"
      fill={props.color}
    />
  </svg>
)

export default IconDropdown
