import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconCopy = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill={props.color}
      d="M4.607 9.359a.545.545 0 0 1 0-.771l4.11-4.11a.545.545 0 1 1 .77.771l-4.11 4.11a.545.545 0 0 1-.77 0Z"
    />
    <path
      fill={props.color}
      d="M4.415 6.726a.545.545 0 0 0-.771 0L1.525 8.845a2.543 2.543 0 1 0 3.596 3.595l2.119-2.118a.545.545 0 1 0-.77-.77L4.35 11.67a1.453 1.453 0 1 1-2.054-2.055l2.119-2.119a.545.545 0 0 0 0-.77ZM9.551 7.24a.545.545 0 0 1 0-.77l2.119-2.12a1.453 1.453 0 1 0-2.055-2.054L7.496 4.414a.545.545 0 1 1-.77-.77l2.119-2.119a2.542 2.542 0 1 1 3.595 3.596L10.322 7.24a.545.545 0 0 1-.77 0Z"
    />
  </svg>
)

export default IconCopy
