import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconChevronLeft = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 35 60"
  >
    <path
      d="M32.9999 50L15.8283 32.8284C14.2662 31.2663 14.2662 28.7337 15.8283 27.1716L32.9999 10C35.209 7.79086 35.209 4.20913 32.9999 2C30.7907 -0.209144 27.209 -0.209146 24.9999 1.99999L2.65674 24.3431C-0.467453 27.4673 -0.467461 32.5326 2.65673 35.6568L24.9999 58C27.209 60.2091 30.7907 60.2091 32.9999 58C35.209 55.7909 35.209 52.2091 32.9999 50Z"
      fill={props.color}
    />
  </svg>
)

export default IconChevronLeft
