import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconCheckIn = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      fill={props.color}
      d="M7.672 4.18a.415.415 0 0 1 .586 0 .412.412 0 0 1 0 .584L5.44 7.574a.415.415 0 0 1-.586 0l-1.113-1.11a.412.412 0 0 1 0-.585.415.415 0 0 1 .586 0l.819.817 2.526-2.517Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M.545 5.647C.545 9.077 5.49 13 5.982 13c.491 0 5.472-3.863 5.472-7.353 0-2.785-2.47-4.992-5.472-5.01C2.96.619.545 2.844.545 5.647Zm9.09.098c0 1.926-1.545 3.619-3.56 3.619-2.016 0-3.712-1.455-3.712-3.62 0-2.164 1.696-3.666 3.712-3.666 2.015 0 3.56 1.74 3.56 3.667Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconCheckIn
