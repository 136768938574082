import React from 'react'
import { IIconProps } from './IIconProps'

interface IProps extends IIconProps {
  secondaryColor?: string
}

const IconTextField = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 24 23"
    fill="none"
  >
    <path
      fill={props.color}
      d="M1.365.955c-.452 0-.818.453-.818 1.013V2.98c0 .559.366 1.013.818 1.013h21.273c.452 0 .818-.454.818-1.013V1.968c0-.56-.366-1.013-.818-1.013H1.365ZM1.365 7.033c-.452 0-.818.453-.818 1.013v1.012c0 .56.366 1.014.818 1.014h19.227c.452 0 .819-.454.819-1.014V8.046c0-.56-.367-1.013-.819-1.013H1.365ZM.547 14.123c0-.559.366-1.013.818-1.013h21.273c.452 0 .818.454.818 1.013v1.013c0 .56-.366 1.013-.818 1.013H1.365c-.452 0-.818-.453-.818-1.013v-1.013ZM1.365 19.188c-.452 0-.818.454-.818 1.013v1.013c0 .56.366 1.013.818 1.013h10.636c.452 0 .819-.453.819-1.013v-1.013c0-.56-.367-1.013-.819-1.013H1.365Z"
    />
  </svg>
)

export default IconTextField
