import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconChevronDown = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 60 35"
  >
    <path
      d="M9.99988 2L27.1715 19.1716C28.7336 20.7337 31.2662 20.7337 32.8283 19.1716L49.9999 1.99999C52.209 -0.209145 55.7907 -0.209145 57.9999 1.99999C60.209 4.20913 60.209 7.79085 57.9999 9.99999L35.6567 32.3431C32.5325 35.4673 27.4672 35.4673 24.343 32.3431L1.99988 9.99999C-0.209259 7.79085 -0.209259 4.20913 1.99988 1.99999C4.20902 -0.209148 7.79074 -0.209144 9.99988 2Z"
      fill={props.color}
    />
  </svg>
)

export default IconChevronDown
