import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconCard = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 22 28"
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M1.59.91C.914.91.365 1.45.365 2.12v23.76c0 .67.55 1.211 1.227 1.211H20c.678 0 1.227-.542 1.227-1.21V2.119c0-.668-.55-1.21-1.227-1.21H1.59Zm9.206 14.696c3.299 0 5.973-2.637 5.973-5.89 0-3.253-2.674-5.89-5.973-5.89-3.3 0-5.974 2.637-5.974 5.89 0 3.253 2.674 5.89 5.974 5.89ZM2.649 19.89c0-.591.487-1.07 1.087-1.07h14.12c.6 0 1.085.479 1.085 1.07 0 .591-.486 1.071-1.086 1.071H3.735c-.6 0-1.086-.48-1.086-1.071Zm1.087 2.142c-.6 0-1.087.48-1.087 1.071s.487 1.07 1.087 1.07h7.603c.6 0 1.086-.479 1.086-1.07 0-.591-.487-1.071-1.086-1.071H3.736Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconCard
