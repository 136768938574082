import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconChevronUp = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 60 35"
  >
    <path
      d="M9.99988 33L27.1715 15.8284C28.7336 14.2663 31.2662 14.2663 32.8283 15.8284L49.9999 33C52.209 35.2091 55.7907 35.2091 57.9999 33C60.209 30.7909 60.209 27.2091 57.9999 25L35.6567 2.65686C32.5325 -0.46733 27.4672 -0.467337 24.343 2.65686L1.99988 25C-0.209259 27.2091 -0.209259 30.7909 1.99988 33C4.20902 35.2091 7.79074 35.2091 9.99988 33Z"
      fill={props.color}
    />
  </svg>
)

export default IconChevronUp
