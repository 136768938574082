import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLink = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 28 22"
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M3.772.318h20.454a2.864 2.864 0 0 1 2.864 2.864v9.41a2.864 2.864 0 0 1-2.864 2.863h-3.933l1.378 1.263c1.238 1.134.436 3.198-1.243 3.198h-1.112l.81 1.301a1.84 1.84 0 0 1-.477 2.46l-2.13 1.556a1.84 1.84 0 0 1-2.684-.575l-1.22-2.137L12.312 24c-1.121 1.273-3.222.48-3.222-1.217v-7.328H3.772A2.864 2.864 0 0 1 .908 12.59V3.182A2.864 2.864 0 0 1 3.772.318ZM24.226 13.41H18.06l-4.465-4.09h7.359a1.227 1.227 0 0 0 0-2.455h-13.5a1.227 1.227 0 1 0 0 2.454h1.412c.075 0 .15-.003.225-.01l-.001.066v4.035H3.772a.818.818 0 0 1-.818-.818V3.182c0-.452.366-.818.818-.818h20.454c.452 0 .819.366.819.818v9.41a.818.818 0 0 1-.819.817Zm-5.489 8.673c.057.091.034.21-.053.274l-2.13 1.555a.204.204 0 0 1-.298-.064l-2.214-3.877a.205.205 0 0 0-.33-.034l-2.628 2.982a.205.205 0 0 1-.358-.135V9.374c0-.178.212-.27.343-.15l9.497 8.7a.205.205 0 0 1-.138.355h-3.69a.205.205 0 0 0-.174.313l2.173 3.49Z"
      clipRule="evenodd"
    />
  </svg>
)

export default IconLink
