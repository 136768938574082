import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconSwitchCompanies = ({ color = '#6F3FF3', size }: IProps) => (
  <svg
    style={{
      width: `${size}px`,
      height: `${size}px`,
    }}
    viewBox="0 0 47 44"
    fill="none"
  >
    <path
      d="M14 31.346c0 1.655.94 2.654 2.538 2.654H30.45c1.598 0 2.55-1 2.55-2.654V11.654C33 10 32.048 9 30.45 9H16.538C14.94 9 14 10 14 11.654v19.692Zm4.994-15.855v-2.183c0-.287.188-.482.47-.482h2.397a.46.46 0 0 1 .482.482v2.183a.46.46 0 0 1-.482.483h-2.397c-.282 0-.47-.196-.47-.483Zm5.734 0v-2.183c0-.287.188-.482.47-.482h2.397c.282 0 .47.195.47.482v2.183c0 .287-.188.483-.47.483h-2.397c-.282 0-.47-.196-.47-.483Zm-5.734 4.7v-2.184c0-.287.188-.482.47-.482h2.397a.46.46 0 0 1 .482.482v2.183a.46.46 0 0 1-.482.483h-2.397c-.282 0-.47-.195-.47-.483Zm5.734 0v-2.184c0-.287.188-.482.47-.482h2.397c.282 0 .47.195.47.482v2.183c0 .287-.188.483-.47.483h-2.397c-.282 0-.47-.195-.47-.483Zm-5.734 4.698v-2.183c0-.287.188-.482.47-.482h2.397a.46.46 0 0 1 .482.482v2.183a.46.46 0 0 1-.482.483h-2.397c-.282 0-.47-.195-.47-.483Zm5.734 0v-2.183c0-.287.188-.482.47-.482h2.397c.282 0 .47.195.47.482v2.183c0 .288-.188.483-.47.483h-2.397c-.282 0-.47-.195-.47-.483Zm-5.476 6.905V28.29c0-.552.376-.896.929-.896h6.615c.658 0 1.01.345 1.01.896v3.504h-8.554Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.003 5.534c.553.486.55 1.333.03 1.854-.52.52-1.361.516-1.919.035A18.095 18.095 0 0 0 6.206 20.71h2.656a.83.83 0 0 1 .627 1.374l-3.575 4.129a.83.83 0 0 1-1.256 0l-3.576-4.13a.83.83 0 0 1 .628-1.373h1.83A20.76 20.76 0 0 1 38.003 5.534ZM9.875 37.938c-.553-.486-.55-1.333-.03-1.853s1.361-.517 1.919-.036a18.094 18.094 0 0 0 29.908-13.286h-2.656a.83.83 0 0 1-.627-1.374l3.575-4.13a.83.83 0 0 1 1.256 0l3.576 4.13a.83.83 0 0 1-.628 1.374h-1.83A20.76 20.76 0 0 1 9.875 37.938Z"
      fill={color}
    />
  </svg>
)

export default IconSwitchCompanies
