import {
  ICompanyMember,
  ICompanyPure,
  ICompany,
  ICompanyLevel,
  ICompanySkill,
  ITeamSkillsPerLevel,
} from '../../common/models/Company'
import { GET_USER_COMPANY } from '../../constants/api'
import { notEmptyTeam } from '../../utils/typeGuards'
import { ICompanyMemberTeam, ICompanyTeam } from './../../common/models/Company'
import { IUser } from './../../common/models/User'
import errorHandler from './errorHandler'
import { getCurrentTerm } from '../../common/helpers/company.helper'

// Handle the request for the user company and process the response
// The response is heavely processed in order to have all data accessible from different points
// For example, the backend returns only one list of members.
// However, you want the corresponding members from that list to be accessible from the "teams" object
export default (user: IUser, token: string) =>
  fetch(GET_USER_COMPANY, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
    .then(errorHandler)
    .then(
      async (
        clientCompanyRequest: Response
      ): Promise<{ clientCompany: ICompany; user: IUser }> => {
        const clientCompany: ICompanyPure = await clientCompanyRequest.json()
        const adaptedCompany = adaptBackendCompanyToFrontendCompany(
          clientCompany
        )
        return {
          clientCompany: adaptedCompany,
          user: addMemberDetailsToAuthUser(user, adaptedCompany),
        }
      }
    )

export const addMemberDetailsToAuthUser = (
  user: IUser,
  company: ICompany
): IUser => {
  const userAsCompanyMember = company.members.find(
    (member) => member.email === user.email
  )
  if (!userAsCompanyMember) {
    throw new Error()
  }

  // Identify the member information about the current authentiated user
  return {
    ...user,
    asCompanyMember: userAsCompanyMember,
    asTeamMember:
      (userAsCompanyMember.teams.length && userAsCompanyMember.teams[0]) ||
      undefined,
  }
}

export const adaptBackendCompanyToFrontendCompany = (
  clientCompany: ICompanyPure
): ICompany => {
  const defaultLevel: ICompanyLevel = {
    rank: 0,
    name: 'Member',
  }

  // For each habit, and for each level, find the corresponding level data from the list of levels in the company
  clientCompany.habits.forEach((habit) => {
    habit.levels.forEach((level) => {
      level.level = clientCompany.levels.find(
        (companyLevel) => companyLevel._id + '' === level.levelId + ''
      ) || { ...defaultLevel }
    })
  })

  // For each skill, find corresponding level and fill inside the skill
  const companySkills: ICompanySkill[] = clientCompany.skills.map((skill) => {
    return {
      ...skill,
      level: skill.levelId
        ? clientCompany.levels.find(
            (level) => level._id + '' === skill.levelId + ''
          )
        : undefined,
    }
  })

  // For each team, set the correct list of skills
  const companyTeams: ICompanyTeam[] = clientCompany.teams.map((team) => {
    const teamSkills = companySkills.filter(
      (skill) => skill.teamId + '' === team._id + ''
    )
    const allLevelSkills = teamSkills.filter((skill) => !skill.levelId)

    // "Reverse" the skills so that you can access all skills required, given a role, instead of getting the roles applicable to a skill
    const skillsPerLevel = teamSkills.reduce((a, b) => {
      if (!b.level) {
        return a
      }
      const level = a.find((e) => e.level._id + '' === b.levelId + '')
      if (level) {
        level.skills.push(b)
      } else {
        a.push({ level: b.level, skills: [b] })
      }
      return a
    }, [] as ITeamSkillsPerLevel[])

    return {
      ...team,
      skills: teamSkills,
      skillsPerLevel,
      allLevelSkills,
      members: [], // Initially set to empty array. Later, this will be modified
    }
  })

  clientCompany.files =
    clientCompany.files && clientCompany.files.length > 0
      ? clientCompany.files.map((file) => ({
          ...file,
          dateCreated: new Date(file.dateCreated),
        }))
      : undefined

  // Sort members alphabetically
  clientCompany.members = clientCompany.members.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  // Sort levels by rank
  clientCompany.levels = clientCompany.levels.sort((a, b) => a.rank - b.rank)

  // For each member, find the teams to which he/she is linked
  const companyMembers: ICompanyMember[] = clientCompany.members.map(
    (member) => {
      const memberTeams: ICompanyMemberTeam[] = member.teams
        .map((team) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const level = clientCompany.levels.find(
            (companyLevel) => companyLevel._id + '' === team.levelId + ''
          )

          const correspondingTeam = companyTeams.find(
            (companyTeam) => companyTeam._id + '' === team.teamId + ''
          )

          return { ...team, level, team: correspondingTeam }
        })
        .filter(notEmptyTeam)
      const level = memberTeams.length ? memberTeams[0].level : undefined
      const currentTeam = memberTeams.length ? memberTeams[0] : undefined

      return { ...member, teams: memberTeams, level, currentTeam }
    }
  )

  // For each team, find the members that are part of that team, and store them in the object
  companyTeams.forEach((team) => {
    team.members = companyMembers.filter(
      (e) =>
        !e.isDisabled &&
        e.teams.find((memberTeam) => memberTeam.team._id + '' === team._id + '')
    )
  })

  return {
    ...clientCompany,
    allHabits: clientCompany.habits,
    habits: clientCompany.habits.filter((habit) => !habit.inactive),
    teams: companyTeams.sort((a, b) => a.name.localeCompare(b.name)),
    members: companyMembers.filter((member) => !member.isDisabled),
    disabledMembers: companyMembers.filter((member) => member.isDisabled),
    allMembers: companyMembers,
    skills: companySkills,
    teamLeadSkills: companySkills.filter(
      (skill) => !skill.levelId && !skill.teamId
    ),
    currentTerm:
      clientCompany.termsInformation && getCurrentTerm(clientCompany),
  }
}
