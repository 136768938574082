import React from 'react'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Types
import { UserCompanyRole } from '../common/enums/userCompanyRole.enum'

// Utils
import { emptyStorage } from '../utils/emptyStorage'
import { isWindowActive } from '../utils/browserUtils'
import { useAppDispatch, useAppState, DEFAULT_APP_STATE } from './AppContext'
import loadAllPdpsService from './services/pdp/loadAllPdps.service'
import loadAllRequestsService from './services/loadAllRequests.service'
import loadAuthorizedUserService from './services/loadAuthorizedUser.service'
import loadCompanyService, {
  adaptBackendCompanyToFrontendCompany,
  addMemberDetailsToAuthUser,
} from './services/loadCompany.service'
import loadCompanyFeedbackAggregationsService from './services/feedbacks/loadCompanyFeedbackAggregations.service'
import loadNewAuthTokenService from './services/loadNewAuthToken.service'
import getOnboardingInstanceService from './services/onboarding-instances/getOnboardingInstance.service'
import { loadPersonalPdpService } from './services/pdp/loadPersonalPdp.service'

const applyWithRetries = async <T extends any>(
  func: Promise<T>,
  leftRetries: number,
  onError?: string | (() => void)
): Promise<T | undefined> => {
  if (leftRetries <= 0) {
    if (onError) {
      if (typeof onError === 'string') {
        alert(`${onError}`)
      } else {
        onError()
      }
    }
    return undefined
  }

  try {
    const res = await func
    return res
  } catch (e) {
    console.error(e)
    // Retry after 1 second
    setTimeout(() => {
      return applyWithRetries(func, leftRetries - 1, onError)
    }, 1000)
  }
}

const AsyncInitializer = () => {
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const signOut = () => {
    emptyStorage()
    appDispatch({
      type: 'updateState',
      payload: { ...DEFAULT_APP_STATE },
    })
    window.location.href = '/sign-in'
  }

  const loadingState = appState.loadingState
  const user = appState.user

  const selectedCompanyId = user?.selectedCompany?._id

  // Check if token has expired every 30 seconds.
  // If token is close to expiring, generate a new one using the refresh token
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      setInterval(() => {
        const cookies = new Cookies()

        const tokenValueFromCookie = cookies.get('auth_token')

        appDispatch({
          type: 'updateUserToken',
          payload: tokenValueFromCookie,
        })

        if (tokenValueFromCookie) {
          const tokenCookie = cookies.get('auth_token_expiration') || 0
          if (Date.now() > tokenCookie - 60 * 10 * 1000) {
            appDispatch({
              type: 'updateShouldLoadNewToken',
              payload: true,
            })
          }
        }
      }, 1000 * 30)
    })()
  }, [])

  // Checks if a new token needs to be generated. If so, sends request to backend using the refresh token
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      const cookies = new Cookies()
      const refreshToken = cookies.get('refresh_token')
      if (
        refreshToken &&
        (loadingState.shouldLoadNewToken || !appState.userAuthToken)
      ) {
        appDispatch({
          type: 'updateShouldLoadNewToken',
          payload: false,
        })

        const newTokenRequest = loadNewAuthTokenService(refreshToken)
        const result = await applyWithRetries(newTokenRequest, 3, () => {
          appDispatch({
            type: 'updateAlertPopup',
            payload: {
              title: t('asyncInitializer.errorSessionExpiredTitle'),
              content: t('asyncInitializer.errorSessionExpiredContent'),
              actionButton: t('general.actions.logOut'),
              onSubmit: () => signOut(),
            },
          })
        })

        if (result) {
          appDispatch({
            type: 'updateUserToken',
            payload: result.authToken,
          })
        }
      }
    })()
  }, [loadingState.shouldLoadNewToken, appState.userAuthToken])

  // Loads the account information and the list of companies of the authenticated user
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (loadingState.shouldLoadUser && appState.userAuthToken) {
        appDispatch({
          type: 'updateShouldLoadUser',
          payload: false,
        })

        const companyRequest = loadAuthorizedUserService(
          appState.userAuthToken,
          appState.newCompanySlug
        )
        const result = await applyWithRetries(
          companyRequest,
          3,
          t('asyncInitializer.errorLoadingGeneral', {
            error: t('asyncInitializer.labelGeneralProfileInfo'),
          })
        )

        if (result) {
          if (result.user?.companies?.length < 1) {
            appDispatch({
              type: 'updateUser',
              payload: result.user,
            })

            history.push('/error', { noCompany: true })

            return
          }

          const company = result.user?.selectedCompany
          if (company) {
            const clientCompany = adaptBackendCompanyToFrontendCompany(company)
            const adaptedUser = addMemberDetailsToAuthUser(
              result.user,
              clientCompany
            )

            appDispatch({
              type: 'updateCompany',
              payload: clientCompany,
            })
            appDispatch({
              type: 'updateUser',
              payload: adaptedUser,
            })
          }
        }
      }
    })()
  }, [loadingState.shouldLoadUser, appState.userAuthToken])

  // Loads the information of the company of the currently authenticated user
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (
        appState.userAuthToken &&
        loadingState.shouldLoadCompany &&
        selectedCompanyId &&
        user
      ) {
        appDispatch({
          type: 'updateShouldLoadCompany',
          payload: false,
        })

        const companyRequest = loadCompanyService(
          user,
          appState.userAuthToken || ''
        )
        const result = await applyWithRetries(
          companyRequest,
          3,
          t('asyncInitializer.errorLoadingGeneral', {
            error: t('asyncInitializer.labelGeneralCompanyInfo'),
          })
        )

        if (result) {
          appDispatch({
            type: 'updateCompany',
            payload: result.clientCompany,
          })
          appDispatch({
            type: 'updateUser',
            payload: result.user,
          })
        }
      }
    })()
  }, [
    loadingState.shouldLoadCompany,
    selectedCompanyId,
    appState.userAuthToken,
  ])

  // Loads the list of all Requests of the user
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (
        loadingState.shouldLoadRequests &&
        user?.asCompanyMember?._id &&
        appState.userAuthToken
      ) {
        appDispatch({
          type: 'updateShouldLoadRequests',
          payload: false,
        })

        const companyRequest = loadAllRequestsService(
          user?.asCompanyMember?._id,
          appState.userAuthToken || ''
        )
        const result = await applyWithRetries(
          companyRequest,
          3,
          t('asyncInitializer.errorLoadingGeneral', {
            error: t('asyncInitializer.labelGeneralRequests'),
          })
        )

        if (result) {
          appDispatch({
            type: 'updateUserRequests',
            payload: result.requests,
          })
        }
      }
    })()
  }, [
    loadingState.shouldLoadRequests,
    user?.asCompanyMember?._id,
    appState.userAuthToken,
  ])

  // Sends a request to refresh the PDP statistics every 30 seconds
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      setInterval(() => {
        if (
          (user?.asCompanyMember?.isAdmin ||
            user?.asCompanyMember?.role === UserCompanyRole.LEAD) &&
          isWindowActive()
        ) {
          appDispatch({
            type: 'updateShouldLoadPdps',
            payload: true,
          })
        }
      }, 1000 * 30)
    })()
  }, [])

  // Loads the statistics of the PDPs if the authenticated member is an admin or a lead
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (
        user?.asCompanyMember &&
        (user?.asCompanyMember?.isAdmin ||
          user?.asCompanyMember?.role === UserCompanyRole.LEAD) &&
        loadingState.shouldLoadPdps &&
        selectedCompanyId &&
        appState.userAuthToken
      ) {
        appDispatch({
          type: 'updateShouldLoadPdps',
          payload: false,
        })

        const companyRequest = loadAllPdpsService(appState.userAuthToken || '')
        const result = await applyWithRetries(
          companyRequest,
          3,
          t('asyncInitializer.errorLoadingGeneral', {
            error: t('asyncInitializer.labelGeneralPdpList'),
          })
        )

        if (result) {
          appDispatch({
            type: 'updatePdpStatistics',
            payload: result.pdpStatistics,
          })
        }
      }
    })()
  }, [
    loadingState.shouldLoadPdps,
    user?.asCompanyMember?.role,
    selectedCompanyId,
    appState.userAuthToken,
  ])

  // Loads the PDP of the authenticated user
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (
        loadingState.shouldLoadPersonalPdp &&
        appState.userAuthToken &&
        appState.company?.members.length &&
        user?.asCompanyMember?._id
      ) {
        appDispatch({
          type: 'updateShouldLoadPersonalPdp',
          payload: false,
        })

        const companyRequest = loadPersonalPdpService(
          user?.asCompanyMember?._id,
          'latest',
          appState.userAuthToken,
          appState.company
        )
        const result = await applyWithRetries(
          companyRequest,
          3,
          t('asyncInitializer.errorLoadingGeneral', {
            error: t('asyncInitializer.labelGeneralPdp'),
          })
        )

        if (result && result.savedPdp) {
          appDispatch({
            type: 'updateUserPdp',
            payload: result.savedPdp,
          })
        }
      }
    })()
  }, [
    loadingState.shouldLoadPersonalPdp,
    appState.userAuthToken,
    appState.company,
    user?.asCompanyMember?._id,
  ])

  // (re)loads onboarding instance
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (loadingState.shouldLoadOnboardingInstance && appState.userAuthToken) {
        appDispatch({
          type: 'updateShouldLoadOnboardingInstance',
          payload: undefined,
        })

        const loadedOnboarding = getOnboardingInstanceService(
          loadingState.shouldLoadOnboardingInstance,
          appState.userAuthToken || ''
        )
        const result = await applyWithRetries(
          loadedOnboarding,
          3,
          t('asyncInitializer.errorLoadingGeneral', {
            error: t('asyncInitializer.labelGeneralOnboardingInstance'),
          })
        )

        if (result && result._id) {
          appDispatch({
            type: 'updateOnboardingInstance',
            payload: result,
          })
        }
      }
    })()
  }, [loadingState.shouldLoadOnboardingInstance, appState.userAuthToken])

  // This does NOT perform any HTTP requests
  // Checks if the company data and the pdp of the current user are defined
  // If yes, connects each PDP statistic to the corresponding member
  React.useEffect(() => {
    ;(() => {
      if (appState.pdpStatistics && appState.company) {
        const pdpStatistics = appState.pdpStatistics
        const company = appState.company

        if (pdpStatistics && company) {
          company.members.forEach((member) => {
            member.basicPdp = pdpStatistics.all.find(
              (pdp) => pdp.memberId === member._id
            )
          })

          appDispatch({
            type: 'updateCompany',
            payload: company,
          })
        }
      }
    })()
  }, [appState.pdpStatistics, appState.company])

  // Loads the aggregated statistics if the authenticated member is an admin
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (
        user?.asCompanyMember &&
        (user.asCompanyMember.isAdmin ||
          user?.asCompanyMember?.role === UserCompanyRole.LEAD) &&
        loadingState.shouldLoadFeedbackAggregations &&
        appState.userAuthToken
      ) {
        appDispatch({
          type: 'updateShouldLoadFeedbackAggregations',
          payload: false,
        })

        const companyRequest = loadCompanyFeedbackAggregationsService(
          appState.userAuthToken || ''
        )
        const result = await applyWithRetries(
          companyRequest,
          3,
          t('asyncInitializer.errorLoadingGeneral', {
            error: t('asyncInitializer.labelGeneralFeedbackAggregations'),
          })
        )

        if (result) {
          appDispatch({
            type: 'updateFeedbackAggregations',
            payload: result,
          })
        }
      }
    })()
  }, [
    loadingState.shouldLoadFeedbackAggregations,
    user?.asCompanyMember?.role,
    appState.userAuthToken,
  ])

  return <></>
}

export default AsyncInitializer
